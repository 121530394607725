import gql from "graphql-tag";

// *************User Queries*************

export const ADD_USER = gql`
  mutation createUser($username: String!, $email: String!, $password: String!) {
    createUser(
      input: { username: $username, email: $email, password: $password }
    ) {
      user {
        id
        username
        email
      }
    }
  }
`;

export const USERS_QUERY = gql`
  {
    allUsers {
      id
      username
      email
      firstName
      lastName
    }
  }
`;

export const USER_QUERY = gql`
  {
    user {
      id
      username
      email
      firstName
      lastName
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

// *************Resource Queries*************

export const RESOURCES_QUERY = gql`
  {
    allResources {
      edges {
        node {
          id
          title
          description
          rrule
          program {
            id
            title
          }
          formatType {
            title
            id
          }
          location
          nurseInterest
          hyperlink
          registrationLink
          virtualMeetingLink
          startDate
          endDate
          facultyLeader
          nextOccurrence
          recurrences
          kl2exclusive
          traexclusive
          maexclusive
          approved
          createdOn
          createdBy{
            username
          }
          icsToggle
          tracks {
            name
            id
          }
          communities {
            name
            id
          }
          competencies {
            name
            id
          }
          region {
            name
          }
          event {
            id
          }
        }
      }
    }
    communities {
      id
      name
    }
    tracks {
      id
      name
    }
    competencies {
      id
      name
    }
    allFormats {
      id
      title
    }
    allPrograms {
      id
      title
    }
  }
`;

export const RESOURCE_QUERY = gql`
  query resource($id: ID!) {
    resource(id: $id) {
      id
      title
      description
      formatType {
        id
        title
      }
      program {
        id
        title
      }
      location
      event {
        id
      }
      nurseInterest
      hyperlink
      speaker
      contactName
      contactEmail
      registrationLink
      virtualMeetingLink
      startDate
      endDate
      rrule
      nextOccurrence
      recurrences
      kl2exclusive
      traexclusive
      maexclusive
      icsToggle
      approved
      createdOn
      createdBy {
        username
        email
        firstName
        lastName
      }
      region {
        name
      }
      tracks {
        id
        name
      }
      communities {
        id
        name
      }
      competencies {
        id
        name
      }
      facultyLeader
    }
    allRegions {
      name
      id
    }
  }
`;

export const ADD_RESOURCE = gql`
  mutation createResource(
    $title: String!
    $description: String!
    $program: String! 
    $formatType: String!
    $nurseInterest: Boolean!
    $hyperlink: String!
    $tracks: [Int]
    $communities: [Int]
    $competencies: [Int]
    $facultyLeader: String!
    $rrule: String
    $startDate: DateTime
    $endDate: DateTime
    $contactName: String
    $contactEmail: String
  ) {
    createResource(
      input: {
        title: $title
        description: $description
        program: $program
        formatType: $formatType
        nurseInterest: $nurseInterest
        hyperlink: $hyperlink
        tracks: $tracks
        communities: $communities
        competencies: $competencies
        facultyLeader: $facultyLeader
        rrule: $rrule
        startDate: $startDate
        endDate: $endDate
        contactName: $contactName
        contactEmail: $contactEmail
      }
    ) {
      resource {
        title
        description
      }
    }
  }
`;

export const EDIT_RESOURCE = gql`
  mutation updateResource(
    $id: String!
    $title: String!
    $description: String
    $location: String
    $speaker: String
    $contactName: String
    $contactEmail: String
    $program: String
    $formatType: String
    $nurseInterest: Boolean
    $hyperlink: String!
    $registrationLink: String!
    $virtualMeetingLink: String!
    $tracks: [String]!
    $communities: [String]!
    $competencies: [String]!
    $startDate: DateTime
    $endDate: DateTime
    $rrule: String
    $region: String
    $traExclusive: Boolean
    $maExclusive: Boolean
    $kl2Exclusive: Boolean
    $icsToggle: Boolean
    $approved: Boolean
    $alwaysInclude: Boolean
  ) {
    updateResource(
      input: {
        id: $id
        title: $title
        description: $description
        location: $location
        speaker: $speaker
        contactName: $contactName
        contactEmail: $contactEmail
        program: $program
        formatType: $formatType
        nurseInterest: $nurseInterest
        hyperlink: $hyperlink
        registrationLink: $registrationLink
        virtualMeetingLink: $virtualMeetingLink
        tracks: $tracks
        communities: $communities
        competencies: $competencies
        startDate: $startDate
        endDate: $endDate
        rrule: $rrule
        region: $region
        traExclusive: $traExclusive
        maExclusive: $maExclusive
        kl2Exclusive: $kl2Exclusive
        icsToggle: $icsToggle
        approved: $approved
        alwaysInclude: $alwaysInclude
      }
    ) {
      resource {
        title
        description
      }
    }
  }
`;

export const DELETE_RESOURCE_QUERY = gql`
  mutation deleteResource($id: String!) {
    deleteResource(input: { id: $id }) {
      resource {
        title
        description
      }
    }
  }
`;

export const RESOURCE_WITH_TRACKS_QUERY = gql`
  query resource($id: ID!) {
    resource(id: $id) {
      id
      title
      description
      program {
        title
        id
      }
      formatType {
        title
        id
      }
      region {
        name
        id
      }
      location
      speaker
      contactName
      contactEmail
      nurseInterest
      hyperlink
      registrationLink
      virtualMeetingLink
      startDate
      endDate
      rrule
      facultyLeader
      kl2exclusive
      maexclusive
      traexclusive
      icsToggle
      approved
      alwaysInclude
      tracks {
        id
        description
        name
      }
      communities {
        id
        description
        name
      }
      competencies {
        id
        description
        name
      }
    }
    tracks {
      name
      description
      id
    }
    communities {
      name
      description
      orderIndex
      id
    }
    competencies {
      name
      description
      id
    }
    allPrograms {
      title
      id
    }
    allFormats {
      title
      id
    }
    allRegions {
      name
      id
    }
  }
`;

export const ALL_REGIONS_QUERY = gql`
  {
    allRegions {
      name
      description
      id
    }
  }
`;

// *************Faculty Queries*************

export const ALL_FACULTY_QUERY = gql`
  {
    allFaculty {
      edges {
        node {
          id
          firstName
          lastName
          department
          email
        }
      }
    }
  }
`;
