import React from "react";
import { Link } from "react-router-dom";
//import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const Profile = ({ match }) => {
  const id = match.params.id;
  const [sendEmail, { loading, error }] = useLazyQuery(EMAIL_QUERY, {
    variables: { personal: true },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  return (
    //const { loading, error, data } = useQuery(USER_QUERY);

    //if (loading) return "Loading...";
    //if (error) return `Error! ${error.message}`;

    <Query query={ME_QUERY} variables={{ id }}>
      {({ data, loading, error }) => {
        if (loading)
          return (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          );
        if (error) return `Error! ${error.message}`;

        return (
          <div className="container-fluid mt-3 px-3">
            <div className="row">
                
              <div className="col col-lg-3 px-1">
                <div className="card">
                  <div className="containter-fluid">
                    <div className="card-body">
                      
                      <h3 className="card-title">{data.me.firstName} {data.me.lastName}</h3>
                      
                      <hr></hr>
                      {data.me.kL2Scholar === true && <p className="card-subtitle mb-2 text-body-secondary fw-bold">K12 Scholar</p>}
                      {data.me.translationalResearchAcademy === true && (
                        <p className="card-subtitle mb-2 text-body-secondary fw-bold">Translational Research Academy Member</p>
                      )}
                      {data.me.mentorAcademy === true && <p className="card-subtitle mb-2 text-body-secondary fw-bold">Mentor Academy Member</p>}
                      
                      {/* Conditional for thematic break. */}
                      {(data.me.kL2Scholar === true || data.me.translationalResearchAcademy === true || data.me.mentorAcademy === true ) && <hr></hr>}
                      
                      <br/>

                      <ul className="list-group">
                        <li className="list-group-item py-0 ps-0" style={{border: "none"}}>
                          <div className="row">
                            <div className="col-4 col-md-4 col-lg-12 col-xl-12 col-xxl-4 ps-3 text-start">
                              <p><i className="fa-solid fa-envelope"></i> <span className="ps-2"><strong>Email:</strong></span></p>
                            </div>
                            <div className="col-8 col-md-8 col-lg-12 col-xl-12 col-xxl-8 my-auto">
                              <p>{data.me.email}</p>
                            </div>
                          </div>
                        </li>

                        {/* If one region is assigned */}
                        {(data.me.region != null && data.me.region.length == 1) &&
                            <li className="list-group-item py-0 ps-0 flex-nowrap" style={{border: "none"}}>
                              <div className="row">
                                <div className="col-4 col-md-4 col-lg-12 col-xl-12 col-xxl-4 align-items-center text-start">
                                  <p><i className="fa fa-globe" aria-hidden="true"></i> <span className="ps-2"><strong>Region:</strong></span></p>
                                </div>
                                <div className="col-8 col-md-8 col-lg-12 col-xl-12 col-xxl-8 my-auto">
                                  <p> {data.me.region.map((region) => region.name + " ")}</p>
                                </div>
                              </div>
                            </li>
                        }

                        {/* If multiple regions are assigned */}
                        {(data.me.region.length != null && data.me.region.length > 1) &&
                        <li className="list-group-item py-0 ps-0" style={{border: "none"}}>
                          <div className="row">
                            <div className="col-4 col-md-4 col-lg-12 col-xl-12 col-xxl-4 ps-3 text-start d-inline-flex align-items-center">
                              <i class="fa fa-globe" aria-hidden="true" ></i><span className="ps-2"><strong>Regions:</strong></span>
                            </div>
                            <div className="col-8 col-md-8 col-lg-12 col-xl-12 col-xxl-8 my-auto">
                              {data.me.region.map((region) => (
                              <div className="list-group" key={region.id}>
                                {region.name != null ? region.name : ""}
                              </div>
                              ))}
                            </div> 
                          </div>
                        </li>
                        }
                      </ul>
                
                
                      <br/>
                      <br/>
                      <div class="vstack gap-2 col-md-12 mx-auto">
                        <Link
                          className="btn btn-secondary btn-block link-underline link-underline-opacity-0"
                          to={`/updateprofile/${id}`}
                        >
                          Edit Profile
                        </Link>
                        <Link
                          className="btn btn-secondary btn-block link-underline link-underline-opacity-0"
                          to={`/communities`}
                        >
                          Need Help Editing Your Profile?
                        </Link>
                        {data.me.isSuperuser && (
                        
                          <button
                            className="btn btn-secondary btn-block"
                            onClick={() => sendEmail()}
                          >
                            Send yourself the TREE Newsletter
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 px-1">
                <div className="card h-100 py-3">
                  <div className='container-fluid'>
                    <h3>Translational Tracks </h3>
                    <hr />
                    {data.me.tracks.map((track) => (
                      <Accordion key={track.id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>{track.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{track.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>

              <div className=" col-lg-3 px-1">
                <div className="card h-100 py-3 ">
                  <div className='container-fluid'>
                    <h3>Learning Communities </h3>
                    <hr />
                    {data.me.communities.map((track) => (
                      <Accordion key={track.id} expanded={false}>
                        <AccordionSummary>
                          <Typography>{track.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{track.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 px-1">
                <div className="card h-100 py-3">
                  <div className='container-fluid'>
                    <h3>Research Competencies </h3>
                    <hr />
                    {data.me.competencies.map((track) => (
                      <Accordion key={track.id}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>{track.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{track.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export const ME_QUERY = gql`
  query($id: String!) {
    me(id: $id) {
      username
      isSuperuser
      email
      kL2Scholar
      translationalResearchAcademy
      mentorAcademy
      region {
        name
        id
      }
      tracks {
        id
        name
        description
      }
      communities {
        id
        name
        description
      }
      competencies {
        id
        name
        description
      }
      firstName
      lastName
      recieveEmail
    }
  }
`;

export const EMAIL_QUERY = gql`
  query($personal: Boolean!) {
    email(personal: $personal) {
      username
    }
  }
`;

export default Profile;
