import React, { useContext } from "react";
import moment from "moment";
// import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { UserContext } from "../App";
// import { Query } from "react-apollo";
// import { EVENT_OCCURRENCES } from "./Home";
import { gql } from "apollo-boost";
// import Subscribe from "./Subscribe";
// import Unsubscribe from "./Unsubscribe";
// import { UPCOMING_TRACK_RESOURCES } from "./UpcomingTrack";

const PastActivities = ({ resourceList }) => {
  const currentUser = useContext(UserContext).user;
  // TODO:  const tracksAndComms = currentUser.communities
  //   .map((community) => community.id)
  //   .concat(currentUser.tracks.map((track) => track.id));
  // const { loading, error, data } = useQuery(UPCOMING_TRACK_RESOURCES, {
  //   variables: {
  //     search: tracksAndComms,
  //   },
  // });
  // var subID = null;

  const exclusiveIsValid = (resource, user) => {
    if (resource.kl2exclusive && !user.kl2Scholar) {
      return false;
    } else if (resource.traexclusive && !user.translationalResearchAcademy) {
      return false;
    } else if (resource.maexclusive && !user.mentorAcademy) {
      return false;
    } else {
      return true;
    }
  };

  //  TODO: const getNextDate = (eventID) => (
  //   <Query query={PAST_EVENT_OCCURRENCES} variables={{ id: eventID }}>
  //     {({ data, loading, error }) => {
  //       if (loading)
  //         return (
  //           <div className="spinner-border" role="status">
  //             <span className="sr-only">Loading...</span>
  //           </div>
  //         );
  //       if (error) return `Error! ${error.message}`;
  //       console.log(data.pastSingleEventOccurrences[-1].title);
  //       console.log(data.pastSingleEventOccurrences[-1].start);
  //       if (
  //         currentUser.isSuperuser &&
  //         data.pastSingleEventOccurrences.length === 0
  //       )
  //         return (
  //           <div>
  //             <b>DATE ERROR</b>
  //           </div>
  //         );
  //       if (data.pastSingleEventOccurrences.length === 0) return <div></div>;
  //       return (
  //         <div>
  //           {moment(new Date(data.pastSingleEventOccurrences[-1].start)).format(
  //             "MMMM D, YYYY"
  //           )}
  //         </div>
  //       );
  //     }}
  //   </Query>
  // );

  const filteredResourceList = resourceList.filter(
    //only return elements with a startDate greater than the current date and are not starred by the user
    (a) =>
      exclusiveIsValid(a.node, currentUser) &&
      a.node.rrule === "" &&
      a.node.startDate !== null &&
      new Date(a.node.startDate).getTime() <= Date.now() &&
      a.node.approved === true
  );

  // TODO: const switchSubscribe = (subscriptionID) => {
  //   const userSubscriptions = currentUser.subscriptionSet;
  //   var isSubscribed = false;
  //   subID = userSubscriptions.find(
  //     ({ resource }) => resource.id === subscriptionID
  //   );
  //   // subID = userSubscriptions.some(item => data.allResources.edges.includes(item))
  //   if (subID !== undefined) {
  //     subID = subID.id;
  //     isSubscribed = true;
  //   }
  //   /*subID = userSubscriptions.find(
  //     ({ resource }) => resource.id === data.resource.id
  //   ).id;*/
  //   return isSubscribed;
  // };

  // if (loading)
  //   return (
  //     <div className="spinner-border" role="status">
  //       <span className="sr-only">Loading...</span>
  //     </div>
  //   );
  // if (error) return `Error! ${error.message}`;
  return (
    <div className="card-body">
      {filteredResourceList
        .filter((a) => a.node.endDate !== null)
        .sort(
          (a, b) =>
            new Date(b.node.endDate).getTime() -
            new Date(a.node.endDate).getTime()
        )
        .slice(0, 3)
        .map((subscription) => (
          <div key={subscription.node.id}>
            {
              <div>
                <div className="btn-group">
                  <div>
                    <Link to={`resourcedetail/${subscription.node.id}`}>
                      <div
                        className="btn-link nav-link"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {subscription.node.title}{" "}
                      </div>
                    </Link>

                    <div>
                      {moment(subscription.node.startDate).format(
                        "MMMM DD, YYYY"
                      )}
                    </div>
                  </div>
                  {/* TODO: {switchSubscribe(subscription.node.id) ? (
                    <Unsubscribe subscriptionId={subID} />
                  ) : (
                    <Subscribe resourceId={subscription.node.id} />
                  )}
                  {(subID = null)} */}
                </div>
                <hr />
              </div>
            }
          </div>
        ))}
      <Link
        to={`../allresources`}
        className="card-footer-item link-underline link-underline-opacity-0 "
        style={{ float: "right", padding: "0" }}
      >
        View More
      </Link>
    </div>
  );
};

export const PAST_EVENT_OCCURRENCES = gql`
  query pastpastSingleEventOccurrences($id: ID!) {
    pastpastSingleEventOccurrences(id: $id) {
      title
      start
      end
    }
  }
`;

export default PastActivities;
