import React, { useState, useContext } from "react";
import { Mutation, Query } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import history from "./History";
// import Multiselect from "react-widgets/lib/Multiselect";
import { TRACKS_QUERY } from "./StepCommunities";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { UserContext } from "../App";
import { useAlert } from "react-alert";

const StepTracks = () => {
  const [tracks] = useState([]);

  const currentUser = useContext(UserContext).user;
  const alert = useAlert();
  
  const tracksMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.tracks.map((track) => {
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxTrack"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.tracks.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const { loading, error, data } = useQuery(STEP_USER_QUERY);
  if (loading) return 0;
  if (error) return 0;

  const handleSubmit = async (event, updateUserTracks) => {
    try {
      event.preventDefault();
      var counterTrack = 0;
      var checks = document.getElementsByTagName("input");
      for (let i = 0; i < checks.length; i++) {
        if (checks[i].id === "checkboxTrack") {
          if (checks[i].checked) {
            tracks.push(checks[i].value);
            counterTrack++;
          }
        }
      }
      if (counterTrack === 0) {
        alert.show("Please select at least one Translational Track", {timeout: 5000, position: 'top center', type: 'info'});
      }
      await updateUserTracks({
        variables: {
          tracks:
            tracks !== undefined
              ? tracks
              : data.me.tracks.map((track) => track.id),
        },
      });
      history.push("/competencies");
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PROFILE_MUTATION_TRACKS}
      refetchQueries={() => [{ query: TRACKS_QUERY }]}
    >
      {(updateUserTracks, { loading, error }) => {
        if (loading)
          return (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          );
        if (error) return `Error! ${error.message}`;
        return (
          <div className="container">
            <div className="mt-3">
              Thanks, {currentUser.firstName}! The next step in building your
              TREE profile will be to select the “Translational Track(s)” that
              you want matches for. The term “Translational Track” may be
              unfamiliar to some so we’ve added descriptions to guide you. If
              you’re still unsure, select as many as you want. You can always go
              back later and edit your profile.
            </div>
            <div className="mt-4">
              <b>
                <div className="text-success">Translational Tracks</div>
              </b>
            </div>
            <form onSubmit={(event) => handleSubmit(event, updateUserTracks)}>
              <div className="mt-5">{tracksMulti()}</div>
              <div className="row mt-5">
                <div className="col">
                  <Link to="/communities" className="button is-link link-underline link-underline-opacity-0">
                    Go Back
                  </Link>
                </div>
                <div className="col">
                  <div className="text-end">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        //alert("Resource was created successfully");
                        //notify.show("Resource was created successfully", "success");
                      }}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
};

// const TRACKS_QUERY = gql`
//   {
//     tracks {
//       name
//       id
//     }
//     communities {
//       name
//       id
//     }
//     competencies {
//       name
//       id
//     }
//     user {
//       tracks {
//         id
//         name
//       }
//       communities {
//         id
//         name
//       }
//       competencies {
//         id
//         name
//       }
//     }
//   }
// `;

export const STEP_USER_QUERY = gql`
  {
    user {
      username
      id
      tracks {
        id
        name
      }
    }
  }
`;

const UPDATE_PROFILE_MUTATION_TRACKS = gql`
  mutation($tracks: [String]) {
    updateUserTracks(input: { tracks: $tracks }) {
      user {
        id
        username
      }
    }
  }
`;

export default StepTracks;
