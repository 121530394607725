import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { USER_QUERY } from "../App";
import { useAlert } from "react-alert";


const Unsubscribe = ({ subscriptionId }) => {
  const alert = useAlert();
  
  return (
    <Mutation
      mutation={UNSUBSCRIBE_MUTATION}
      variables={{ id: subscriptionId }}
      onCompleted={(data) => {
        console.log({ data });
      }}
      refetchQueries={() => [{ query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } }]}
    >
      {(deleteSubscription, { loading, error }) => {
        if (error) return `Error ${error.message}`;
        return (
          <div>
            <button
              className="btn btn-lg"
              style={{ backgroundColor: "transparent" }}
              onClick={async (event) => {
                try {
                  await deleteSubscription();
                }
                catch (err) {
                   alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
                }
              }}
            >
              <i class="fa-solid fa-star" style={{ color: "#cacc3f" }} />
            </button>
          </div>
        );
      }}
    </Mutation>
  );
};

const UNSUBSCRIBE_MUTATION = gql`
  mutation deleteSubscription($id: String!) {
    deleteSubscription(input: { id: $id }) {
      subscription {
        user {
          username
        }
      }
    }
  }
`;

export default Unsubscribe;
