import React from "react";

import logofooter from "./img/logofooter.png";
import { default as wfsmlogo } from "./img/wfsmlogo.svg";
import "../static/footer.css";

export default function Footer() {
  return (
    <footer>
      <br />
      <div className="banner-full-width connect">
        <div className="container">
          <div className="">
            <div className="col-sm-12">
              <section className="banner">
                <h1 className="">Connect with Us</h1>
                <ul className="py-2">
                  <li>
                    <a
                      href="https://www.facebook.com/wakeforestmed/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Facebook"
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/wakeforestmed"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Twitter"
                    >
                      <i className="fa fa-x" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/c/wakeforestschoolofmedicine"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="YouTube"
                    >
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/wakeforestmed/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="">
          <div className="row">
            <div className="col col-sm-4">
              <a href="https://www.wakehealth.edu" className="footer-brand">
                <img
                  /*src="/-/media/WakeForest/School/Images/Logos-and-Signatures/logo-footer.png"*/
                  src={wfsmlogo}
                  title=""
                  className="logo"
                  alt="Wake Health Logo"
                />
              </a>
              <p>
                Wake Forest Baptist Health does not discriminate on the basis of
                race, color, national origin, sex, sexual orientation, gender
                identity, age or disability.
              </p>
              <br />
              <p>
                <a
                  href="https://www.wakehealth.edu/Patient-and-Family-Resources/Services-and-Amenities/Notice-of-Nondiscrimination"
                  className="btn-link"
                  style={{ color: "#03806f" }}
                >
                  Learn more about our nondiscrimination policy and services
                  available to you.
                </a>
              </p>
            </div>
            <div className="col col-sm-2">
              <nav>
                <ul>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://newsroom.wakehealth.edu/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Newsroom
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Careers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Training-Programs"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Training Programs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/Patient-and-Family-Resources/Calendar"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Calendar
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col col-sm-2">
              <nav>
                <ul>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Financial-Statement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Financial Statement
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Serving-Our-Communities"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Serving Our Communities
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Inclusion-and-Diversity"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Inclusion and Diversity
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Conflict-of-Interest"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Conflict of Interest
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/Patient-and-Family-Resources/Billing-and-Insurance/Financial-Assistance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Financial Assistance
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col col-sm-2">
              <nav>
                <ul>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Our-Website/Site-Policies-and-Statements"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Site Policies and Statements
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Our-Website/Site-Feedback"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Site Feedback
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://school.wakehealth.edu/Campus-and-Community-Life/Campus-Safety-and-Security"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Annual Crime and Fire Report
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Audit-and-Compliance-Office/Notice-of-Privacy-Practices"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notice of Privacy Practices
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col col-sm-2">
              <nav>
                <ul>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Our-Website/Employees"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Employees
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Volunteer-Services"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Volunteer Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.wakehealth.edu/About-Us/Philanthropy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Philanthropy
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      style={{ color: "#9D7C2F" }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `mailto:learningtree@wakehealth.edu?subject=Learning TREE Feedback`
                        );
                      }}

                    >
                      Leave Us Some Feedback
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <hr />
        <section className="disclaimer">
          <p>
            Disclaimer: The information on this website is for general
            informational purposes only and SHOULD NOT be relied upon as a
            substitute for sound professional medical advice, evaluation or care
            from your physician or other qualified health care provider.
          </p>
          <br />
          <p className="">
            <i className="fa fa-copyright" /> Wake Forest Baptist Health,
            Medical Center Boulevard, Winston-Salem, NC 27157. All Rights
            Reserved.
          </p>
          <br />
          <p>
            This site was developed by{" "}
            <a
              href="https://ctsi.wakehealth.edu"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-link text-dark"
            >
              Wake Forest School of Medicine Clinical and Translational Sciences
              Institute
            </a>
          </p>
          <br />
        </section>
      </div>
    </footer>
  );
}
