import React, { useContext } from "react";
import moment from "moment";
// import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import PastActivities from "./PastActivities";
import UpcomingTrack from "./UpcomingTrack";
import UpcomingCommunity from "./UpcomingCommunity";
import SelfPaced from "./SelfPaced";
import history from "./History";
import { UserContext } from "../App";
import Unsubscribe from "../components/Unsubscribe";
import { downloadICS } from "./ResourceDetail";

export const checkExclusive = (a, user) => {
  if (
    !(a.resource.kl2exclusive && !user.kL2Scholar) &&
    !(a.resource.traexclusive && !user.translationalResearchAcademy) &&
    !(a.resource.maexclusive && !user.mentorAcademy)
  ) {
    return true;
  } else {
    return false;
  }
};

const Home = () => {
  const currentUser = useContext(UserContext).user;
  const resources = useContext(UserContext).allResources.edges;

  const exclusiveIsValid = (resource, user) => {
    if (resource.kl2exclusive && !user.kl2Scholar) {
      return false;
    } else if (resource.traexclusive && !user.translationalResearchAcademy) {
      return false;
    } else if (resource.maexclusive && !user.mentorAcademy) {
      return false;
    } else {
      return true;
    }
  };

  const findMatches = (resourceCommunities, userCommunities) => {
    let result = [];
    resourceCommunities.forEach((val) => {
      if (userCommunities.find((element) => element.id === val.id)) {
        result.push(val.id);
      }
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const matchesCommunity = (resource, user) => {
    if (
      user.subscriptionSet.find(
        (element) => element.resource.id === resource.id
      ) === undefined
    ) {
      if (findMatches(resource.communities, user.communities) === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const matchesTrack = (resource, user) => {
    if (
      user.subscriptionSet.find(
        (element) => element.resource.id === resource.id
      ) === undefined
    ) {
      if (findMatches(resource.tracks, user.tracks) === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const communityResourceList = resources.filter(
    (a) =>
      exclusiveIsValid(a.node, currentUser) &&
      matchesCommunity(a.node, currentUser) &&
      a.node.formatType.title !== "Self-paced" &&
      a.node.approved === true
  );

  // todo: change !matchesCommunity to not in communityresourcelist
  const trackResourceList = resources.filter(
    (a) =>
      exclusiveIsValid(a.node, currentUser) &&
      matchesTrack(a.node, currentUser) &&
      !matchesCommunity(a.node, currentUser) &&
      a.node.formatType.title !== "Self-paced" &&
      a.node.approved === true
  );

  const selfPacedResourceList = resources.filter(
    (a) =>
      exclusiveIsValid(a.node, currentUser) &&
      a.node.formatType.title === "Self-paced" &&
      a.node.approved === true
  );

  // TODO: const downloadICS = (resource) => {
  //   const ics = require("ics");
  //   const FileSaver = require("file-saver");
  //   let location = "";
  //   if (resource.location !== null) {
  //     location = resource.location;
  //   }
  //   let start = moment(resource.startDate + resource.startTime, "YYYY-MM-DDLT")
  //     // .format("YYYY-M-D-H-m")
  //     // .split("-");
  //     .toDate();
  //   let end = moment(resource.endDate + resource.endTime, "YYYY-MM-DDLT")
  //     // .format("YYYY-M-D-H-m")
  //     // .split("-");
  //     .toDate();

  //   let event = {
  //     title: resource.title,
  //     start: [
  //       start.getFullYear(),
  //       start.getMonth() + 1,
  //       start.getDate(),
  //       start.getHours(),
  //       start.getMinutes(),
  //     ],
  //     startInputType: "local",
  //     startOutputType: "local",
  //     location: location,
  //     end: [
  //       end.getFullYear(),
  //       end.getMonth() + 1,
  //       end.getDate(),
  //       end.getHours(),
  //       end.getMinutes(),
  //     ],
  //     // description: resource.description,
  //     htmlContent:
  //       "<!DOCTYPE html><html><body><div style='font-family: sans-serif;'><div style='color: #9D7C2F; font-size: 18px;'>" +
  //       resource.title +
  //       "</div><br />" +
  //       resource.description +
  //       "<br /><div style='font-size: 14px;'>" +
  //       resource.hyperlink +
  //       "</div></div></body></html>",
  //     url: resource.hyperlink,
  //     recurrenceRule: resource.rrule.slice(6),
  //   };

  //   ics.createEvent(event, (error, value) => {
  //     if (error) {
  //       console.log(error);
  //       return;
  //     }
  //     var blob = new Blob([value], { type: "ics" });
  //     FileSaver.saveAs(blob, "event.ics");
  //   });
  // };


  const fitleredSubSet = currentUser.subscriptionSet.filter(
    //only return elements with an endDate greater than the current date
    (a) =>
      (((a.resource.recurrences === "" &&
      new Date(a.resource.startDate).getTime() >= Date.now()) ||
      (a.resource.recurrences !== "" &&
      new Date(a.resource.nextOccurrence).getTime() >= new Date().getTime())) ||
        a.resource.formatType.title === "Self-paced") &&
      exclusiveIsValid(a.resource, currentUser)
  );

  return (
    <div>
      {(currentUser.tracks.length === 0 ||
        currentUser.communities.length === 0 ||
        currentUser.competencies.length === 0) &&
        history.push("communities")}
      {/* TODO: {process.env.NODE_ENV === "development" && (
        <button
          style={{ display: "none" }}
          onClick={alert.show("This is the TREE DEV site")}
        ></button>
      )} */}
      {/* TODO: <Query query={RESOURCES_QUERY}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            );
          if (error) return `Error! ${error.message}`;
          const subSet = currentUser.subscriptionSet;

          return ( */}
      <div>
        <div className="container" style={{ maxWidth: "90%" }}>
          <div className=" row row-cols-xl-3 row-cols-lg-1 row-cols-md-1 row-cols-sm-1 mb-3 mt-3 text-center">
            <div className="col">
            <div className="card mb-4 shadow h-100">
              <div className="card-header">
                <div className="my-0 fw-bold">Starred Resources</div>
              </div>
              <div className="card-body">
                {fitleredSubSet
                  .sort(
                    //sort by ascending date
                    (a, b) =>
                      new Date(a.resource.startDate).getTime() -
                      new Date(b.resource.startDate).getTime()
                  )
                  .slice(0, 3) //only return the first three elements
                  .map((subscription) => (
                    <div key={subscription.resource.id}>
                      {
                        <div className="text-start">
                          <div className="btn-group text-start">
                            <Unsubscribe subscriptionId={subscription.id} />
                            <div className={`${subscription.resource.formatType.title ===
                                "Self-paced" && "d-flex align-items-center"}`}>
                              <Link
                                to={{
                                  pathname: `resourcedetail/${subscription.resource.id}`,
                                  state: {
                                    resource: subscription.resource,
                                  },
                                }}
                              >
                                <div
                                  className="btn-link ms-2 nav-link"
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {subscription.resource.title}{" "}
                                </div>
                              </Link>

                              {subscription.resource.formatType.title !==
                                "Self-paced" && (
                                <div className="d-flex align-items-start align-items-center">
                                  {subscription.resource.icsToggle === true && (
                                    <button
                                      type="button"
                                      className="btn btn-sm d-inline-block "
                                      style={{
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={function () {
                                        downloadICS(subscription.resource);
                                      }}
                                    >
                                      <i
                                        className="fa fa-calendar"
                                        title="Add To Calendar"
                                      />
                                    </button>
                                  )}
                                  {subscription.resource.icsToggle ===
                                    false && (
                                    <a
                                      href={
                                        subscription.resource.registrationLink
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "black" }}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        title="Register"
                                      />
                                    </a>
                                  )}
                                  <div className="d-inline-block ms-2">
                                    {subscription.resource.recurrences !== ""
                                      ? moment(subscription.resource.nextOccurrence).format("MMMM D, YYYY")
                                      : moment(
                                          subscription.resource.startDate
                                        ).format("MMMM D, YYYY")}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <hr />
                        </div>
                      }
                    </div>
                  ))}
                {fitleredSubSet.length > 3 && (
                  
                  <Link
                    to={`../allresources`}
                    className="card-footer-item link-underline link-underline-opacity-0 "
                    style={{ float: "right", padding: "0" }}
                  >
                    View More
                  </Link>
                )}
                
              </div>
            </div>
            </div>
            <div className="col ">
              <div className="card mb-4 shadow h-100">
                <div className="card-header">
                  <div className="my-0 fw-bold">
                    Upcoming For Your Learning Community
                  </div>
                </div>
                <UpcomingCommunity resourceList={communityResourceList} />
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 shadow h-100">
                <div className="card-header">
                  <div className="my-0 fw-bold">
                    Upcoming For Your Translational Track
                  </div>
                </div>
                <UpcomingTrack resourceList={trackResourceList} />
              </div>
            </div>
          </div>
        </div>
        
        <div className="container" style={{ maxWidth: "90%" }}>
          <div className="row row-cols-lg-2 row-cols-sm-1  mb-3 text-center">
            <div className="col-md-6">
              <div className="card mb-4 shadow h-100">
                <div className="card-header">
                  <div className="my-0 fw-bold">
                    Historical Resources
                  </div>
                </div>
                <PastActivities resourceList={resources} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-4 shadow h-100">
                <div className="card-header">
                  <div className="my-0 fw-bold">
                    Self Paced Resources
                  </div>
                </div>
                <SelfPaced resourceList={selfPacedResourceList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EVENT_OCCURRENCES = gql`
  query singleEventOccurrences($id: ID!) {
    singleEventOccurrences(id: $id) {
      title
      start
      end
    }
  }
`;

export default Home;
