import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "@apollo/react-hooks";
import { UserContext } from "../App";

const PendingApprovals = () => {
  const currentUser = useContext(UserContext).user;
  const { loading, error, data } = useQuery(USER_HAS_RIGHTS_QUERY, {
    variables: { groupNames: ["Resource Creator"] },
  });

  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (error) return `Error Occurred! ${error.message}`;

  return (
    <div className="container mt-2">
      {currentUser.isSuperuser === true && (
        <div>
          <h3>Pending Approvals</h3>
          <hr />
          <div>
            {data.allResources.edges
              .filter((a) => a.node.approved === false)
              .map((resource) => (
                <div key={resource.node.id}>
                  <div className="row">
                    <Link
                      to={{
                        pathname: `resourcedetail/${resource.node.id}`,
                        state: {
                          resource: resource.node,
                        },
                      }}
                    >
                      <h4 className="btn-link" style={{ color: "black" }}>
                        {resource.node.title}
                      </h4>
                    </Link>
                  </div>
                  {ReactHtmlParser(resource.node.description)}
                  <div className="mt-1">
                    <div>
                      Added On:{" "}
                      {moment(resource.node.createdOn).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const USER_HAS_RIGHTS_QUERY = gql`
  query($groupNames: [String]) {
    userHasPermission(groupNames: $groupNames)
    allResources {
      edges {
        node {
          title
          id
          approved
          createdOn
          description
        }
      }
    }
  }
`;

export default PendingApprovals;
