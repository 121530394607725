import React, { useContext } from "react";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
//import logo from "./img/logo.png";
import { default as wfsmlogo } from "./img/wfsmlogo.svg";

const Header = () => {
  const currentUser = useContext(UserContext);
  //const { language, labels } = this.props;
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white py-0">
        <div className="container">
          <a href="https://www.wakehealth.edu/#" className="navbar-brand">
            <img
              src={wfsmlogo}
              title=""
              alt="logo"
              className="logo"
              width="244px"
              height="60px"
            />
          </a>
          <div className="nav justify-content-end d-none d-sm-block">
            {/*<Link
                to={`/`}
                className="btn btn-link text-dark align-self-center"
                style={{
                  borderLeft: "2px solid #ebebeb",
                  borderRadius: "1px",
                  backgroundColor: "#f5f5f5",
                  width: "95px",
                  height: "95px",
                  transition: "background-color 0.5s ease"
                }}
              >
                <div>
                  <div>
                    <i className="fa fa-comment" />
                  </div>
                  <div>Contact Us</div>
                </div>
              </Link>*/}
            {/*
            {currentUser && (
              <Link
                to={`/`}
                className="btn btn-link text-dark align-self-center"
                style={{
                  borderLeft: "2px solid #ebebeb",
                  borderRadius: "1px",
                  backgroundColor: "#f5f5f5",
                  width: "95px",
                  height: "95px",
                  transition: "background-color 0.5s ease"
                }}
              >
                <div>
                  <div>
                    <i className="fa fa-search" />
                  </div>
                  <div>Search Resources</div>
                </div>
              </Link>
              )}*/}
            {!currentUser && (
              <Link
                to={`/`}
                className="btn btn-link text-dark align-self-center"
                style={{
                  borderLeft: "2px solid #ebebeb",
                  borderRadius: "1px",
                  backgroundColor: "#f5f5f5",
                  width: "95px",
                  height: "95px",
                  transition: "background-color 0.5s ease",
                }}
              >
                <div>
                  <div>
                    <i className="fa fa-power-off" />
                  </div>
                  <div className="btn btn-link" style={{ color: "black" }}>
                    Login
                  </div>
                </div>
              </Link>
            )}

            {/* {currentUser && <Logoff />} */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
