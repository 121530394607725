import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Query } from "react-apollo";
import RRuleGenerator from "react-rrule-generator";
// import moment from "moment";
import "react-widgets/dist/css/react-widgets.css";
// import Multiselect from "react-widgets/lib/Multiselect";
import Select from "react-select";
import history from "./History";
import { UserContext, USER_QUERY } from "../App";
// import { gql } from "apollo-boost";
import {
  EDIT_RESOURCE,
  RESOURCE_WITH_TRACKS_QUERY,
  RESOURCE_QUERY,
  // ALL_REGIONS_QUERY,
} from "../Queries";
// import { ALL_FORMATS_QUERY, ALL_PROGRAMS_QUERY } from "./AddResource";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
// import { TRACKS_QUERY } from "./UpdateProfile";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAlert } from "react-alert";

const EditResource = ({ match }) => {
  const alert = useAlert();
  
  const tracksMulti = () => (
    <Query
      query={RESOURCE_WITH_TRACKS_QUERY}
      variables={{ id: match.params.id }}
    >
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.tracks.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxTrack"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.resource.tracks.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      disabled={
                        kl2Exclusive === true ||
                        maExclusive === true ||
                        traExclusive === true
                      }
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const communitiesMulti = () => (
    <Query
      query={RESOURCE_WITH_TRACKS_QUERY}
      variables={{ id: match.params.id }}
    >
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.communities
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((track) => {
            return (
              <Accordion expanded={false} key={track.id}>
                <AccordionSummary>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        id="checkboxCommunity"
                        style={{ color: "#5cb85c" }}
                        defaultChecked={data.resource.communities.some(
                          (el) => el.id === track.id
                        )}
                        value={track.id}
                        disabled={
                          kl2Exclusive === true ||
                          maExclusive === true ||
                          traExclusive === true
                        }
                        color={"secondary"}
                      />
                    }
                    label={track.name}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{track.description}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          });
      }}
    </Query>
  );

  const competenciesMulti = () => (
    <Query
      query={RESOURCE_WITH_TRACKS_QUERY}
      variables={{ id: match.params.id }}
    >
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.competencies.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCompetency"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.resource.competencies.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      disabled={
                        kl2Exclusive === true ||
                        maExclusive === true ||
                        traExclusive === true
                      }
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const currentUser = useContext(UserContext).user;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("empty");
  const [speaker, setSpeaker] = useState("empty");
  const [contactName, setContactName] = useState("empty");
  const [contactEmail, setContactEmail] = useState("empty");
  const [program, setProgram] = useState("");
  const [formatType, setFormatType] = useState("");
  const [region, setRegion] = useState("");
  const [nurseInterest] = useState(false);
  const [hyperlink, setHyperlink] = useState("empty");
  const [registrationLink, setRegistrationLink] = useState("empty");
  const [virtualMeetingLink, setVirtualMeetingLink] = useState("empty");
  const [facultyLeader] = useState("");
  const [rrule, setRrule] = useState("");
  const [startDate, setStartDate] = useState();
  const [startTime] = useState();
  const [endDate, setEndDate] = useState();
  const [endTime] = useState();
  const [tracks] = useState([]);
  const [communities] = useState([]);
  const [competencies] = useState([]);
  const [kl2Exclusive, setKl2Exclusive] = useState("false");
  const [maExclusive, setMaExclusive] = useState("false");
  const [traExclusive, setTraExclusive] = useState("false");
  const [icsToggle, setIcsToggle] = useState("empty");
  const [checked, setChecked] = useState(false);
  const [approved, setApproved] = useState("false");
  const [alwaysInclude, setAlwaysInclude] = useState("false")

  const { loading, error, data } = useQuery(
    RESOURCE_WITH_TRACKS_QUERY,
    {
      variables: {
        id: match.params.id,
      },
    },
    { onCompleted: (data) => setRrule(data.resource.rrule) }
  );

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const [updateResource] = useMutation(EDIT_RESOURCE, {
    refetchQueries: [
      { query: RESOURCE_QUERY, variables: { id: match.params.id } },
      { query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } },
    ],
  });

  if (loading) return <div>Fetching Resource...</div>;
  if (error) return <div>Error fetching resource</div>;

  const resource = data;

  return (
    <div className="container m-t-20">
      <h1 className="page-title">Edit Resource</h1>
      <div>
        Fields marked with <label className="required"></label> are required
      </div>
      <div className="newnote-page m-t-20">
        <form 
          onSubmit={async (e) => {
            try {
              e.preventDefault();
              var checks = document.getElementsByTagName("input");
              var counterTrack = 0;
              var counterCommunity = 0;
              var counterCompetency = 0;
              for (let i = 0; i < checks.length; i++) {
                if (checks[i].id === "checkboxTrack") {
                  if (checks[i].checked) {
                    tracks.push(checks[i].value);
                    counterTrack++;
                  }
                } else if (checks[i].id === "checkboxCommunity") {
                  if (checks[i].checked) {
                    communities.push(checks[i].value);
                    counterCommunity++;
                  }
                } else if (checks[i].id === "checkboxCompetency") {
                  if (checks[i].checked) {
                    competencies.push(checks[i].value);
                    counterCompetency++;
                  }
                }
              }
              if (
                kl2Exclusive === 'false' &&
                maExclusive === 'false' &&
                traExclusive === 'false'
              ) {
                if (counterTrack === 0) {
                  alert.show("Please select at least one Translational Track", {timeout: 5000, position: 'top center', type: 'info'});
                  return;
                } else if (counterCommunity === 0) {
                  alert.show("Please select at least one Learning Community", {timeout: 5000, position: 'top center', type: 'info'});
                  return;
                } else if (counterCompetency === 0) {
                  alert.show("Please select at least one Research Competency", {timeout: 5000, position: 'top center', type: 'info'});
                  return;
                }
              }
              // else {
              //   tracks.length = 0;
              //   communities.length = 0;
              //   competencies.length = 0;
              // }
              await updateResource({
                variables: {
                  id: resource.resource.id,
                  title: title ? title : resource.resource.title,
                  description: description
                    ? description
                    : resource.resource.description,
                  location:
                    location !== "empty" ? location : resource.resource.location,
                  speaker:
                    speaker !== "empty" ? speaker : resource.resource.speaker,
                  contactName:
                    contactName !== "empty" ? contactName : resource.resource.contactName,
                  contactEmail:
                    contactEmail !== "empty" ? contactEmail : resource.resource.contactEmail,
                  program: program ? program : resource.resource.program.title,
                  formatType: formatType
                    ? formatType
                    : resource.resource.formatType.title,
                  nurseInterest: nurseInterest
                    ? nurseInterest
                    : resource.resource.nurseInterest,
                  startDate: startDate ? startDate : resource.resource.startDate,
                  startTime: startTime ? startTime : resource.resource.startTime,
                  endDate: endDate ? endDate : resource.resource.endDate,
                  endTime: endTime ? endTime : resource.resource.endTime,
                  hyperlink:
                    hyperlink !== "empty"
                      ? hyperlink
                      : resource.resource.hyperlink,
                  kl2Exclusive:
                    kl2Exclusive !== "false"
                      ? kl2Exclusive
                      : resource.resource.kl2exclusive,
                  maExclusive:
                    maExclusive !== "false"
                      ? maExclusive
                      : resource.resource.maexclusive,
                  traExclusive:
                    traExclusive !== "false"
                      ? traExclusive
                      : resource.resource.traexclusive,
                  icsToggle:
                    icsToggle !== "empty"
                      ? icsToggle
                      : resource.resource.icsToggle,
                  registrationLink:
                    registrationLink !== "empty"
                      ? registrationLink
                      : resource.resource.registrationLink,
                  virtualMeetingLink:
                    virtualMeetingLink !== "empty"
                      ? virtualMeetingLink
                      : resource.resource.virtualMeetingLink,
                  rrule: rrule ? rrule : resource.resource.rrule,
                  facultyLeader: facultyLeader
                    ? facultyLeader
                    : resource.resource.facultyLeader,
                  tracks:
                    tracks !== undefined
                      ? tracks
                      : data.me.tracks.map((track) => track.id),
                  communities:
                    communities !== undefined
                      ? communities
                      : data.me.communities.map((track) => track.id),
                  competencies:
                    competencies !== undefined
                      ? competencies
                      : data.me.competencies.map((track) => track.id),
                  region: region ? region : resource.resource.region.title,
                  approved:
                    approved !== "false" ? approved : resource.resource.approved,
                  alwaysInclude:
                    alwaysInclude !== "false" ? alwaysInclude : resource.resource.alwaysInclude,
                  // region:
                  //   region.value != undefined
                  //     ? region.value.map((region) => region.id)
                  //     : data.me.region.map((region) => region.id),
                },
              });
              history.goBack();
              alert.show('Resource successfully updated!', {timeout: 5000, position: 'top center', type: 'success'});
            }
            catch(err){
              alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
            }
          }}
        >
          <div className="field">
            <label className="required">Title</label>
            <div className="control">
              <input
                className="input"
                name="title"
                type="text"
                placeholder="Title"
                defaultValue={resource.resource.title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <ReactQuill
                theme="snow"
                onChange={setDescription}
                modules={modules}
                defaultValue={resource.resource.description}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="field">
                <label className="label required">Program</label>
                <div className="control">
                  {/* <select
                    className="form-control"
                    required
                    name="format"
                    id="format"
                    defaultValue={resource.resource.program.title}
                    onChange={(e) => {
                      setProgram(e.target.value);
                    }}
                  >
                    {console.log(resource.resource.program.title)}
                    {displayPrograms()}
                  </select> */}
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.title}
                    options={resource.allPrograms}
                    defaultValue={resource.resource.program}
                    onChange={(e) => {
                      setProgram(e.title);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label className="label">Speaker</label>
                <div className="control">
                  <input
                    className="input"
                    name="speaker"
                    type="text"
                    placeholder="Speaker"
                    defaultValue={resource.resource.speaker}
                    onChange={(e) => setSpeaker(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label className="label required">Contact Name</label>
                <div className="control">
                  <input
                    className="input"
                    name="contactName"
                    type="text"
                    placeholder="Contact Name"
                    defaultValue={resource.resource.contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label className="label required">Contact Email</label>
                <div className="control">
                  <input
                    className="input"
                    name="contactEmail"
                    type="email"
                    placeholder="Contact Email"
                    defaultValue={resource.resource.contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label className="label">Format</label>
                <div className="control">
                  {/* <select
                    className="form-control"
                    name="format"
                    id="format"
                    defaultValue={resource.resource.formatType.title}
                    onChange={(e) => {
                      setFormatType(e.target.value);
                    }}
                  >
                    <option value="" placeholder="Format"></option>
                    {displayFormats()}
                  </select> */}
                  <Select
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.title}
                    options={resource.allFormats}
                    defaultValue={resource.resource.formatType}
                    onChange={(e) => {
                      setFormatType(e.title);
                      if (e.title == 'Self-paced'){
                        setRrule("");
                        setChecked(false);
                        setStartDate();
                        setEndDate();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {((resource.resource.formatType.title !== "Self-paced" &&
            resource.resource.formatType.title !== "Instructor-led, online" &&
            formatType !== "Self-paced" &&
            formatType !== "Instructor-led, online") ||
            ((resource.resource.formatType.title === "Self-paced" ||
              resource.resource.formatType.title === "Instructor-led, online") &&
              formatType !== "" &&
              formatType !== "Self-paced" &&
              formatType !== "Instructor-led, online")) && (
            <div>
              <div className="row">
                <div className="col">
                  <div className="field">
                    <label className="label">Region</label>
                    <div className="control">
                      <Select
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        options={resource.allRegions}
                        defaultValue={resource.resource.region}
                        onChange={(e) => {
                          setRegion(e.name);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <label className="label required">Location</label>
                  <div className="control">
                    <input
                      required
                      className="input"
                      name="location"
                      type="text"
                      placeholder="Location"
                      defaultValue={resource.resource.location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col">
                  <label className="label required">Time Zone</label>
                  <div className="control">
                    <select
                      className="form-control"
                      required
                      name="format"
                      id="format"
                    >
                      <option value="est" selected>
                        EST
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {((resource.resource.formatType.title !== "Self-paced" &&
            formatType !== "Self-paced") ||
            (resource.resource.formatType.title === "Self-paced" &&
              formatType !== "" &&
              formatType !== "Self-paced")) && (
            <div>
              <div className="row">
                <div className="col">
                  <label className="label">Start Time</label>
                  <input
                    className="input"
                    name="startDate"
                    type="datetime-local"
                    defaultValue={resource.resource.startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                <div className="col">
                  <label className="label">End Time</label>
                  <input
                    className="input"
                    name="endDate"
                    type="datetime-local"
                    defaultValue={resource.resource.endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                 <FormControlLabel
                    control={
                      <Checkbox
                        id="checkboxRecur"
                        style={{ color: "#5cb85c" }}
                        color={"secondary"}
                        onChange={(e) => setChecked(!checked)}
                      />
                    }
                
                    label={"Will this event recur?"}
                  />
                </div>
                <div className="col">
                  <div className="row form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={(e) => setIcsToggle(true)}
                      defaultChecked={resource.resource.icsToggle}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Calendar Event Link
                    </label>
                  </div>
                  <div className="row form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={(e) => setIcsToggle(false)}
                      defaultChecked={!resource.resource.icsToggle}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Registration Link
                    </label>
                  </div>
                </div>
              </div>
              {checked && (
                <div className="field">
                  <RRuleGenerator
                    value={rrule}
                    onChange={(e) => {
                      setRrule(e);
                    }}
                  />
                  {/* <RRuleGenerator onChange={e => console.log(e)} /> */}
                </div>
              )}
            </div>
          )}
          <div className="row mt-2">
            <div className="col">
              <div className="field">
                <label className="label">Event Info</label>
                <div className="control">
                  <input
                    className="input"
                    name="hyperlink"
                    type="url"
                    placeholder="Hyperlink"
                    defaultValue={resource.resource.hyperlink}
                    onChange={(e) => setHyperlink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label className="label">Registration Link</label>
                <div className="control">
                  <input
                    className="input"
                    name="registrationLink"
                    type="url"
                    placeholder="Registration Link"
                    defaultValue={resource.resource.registrationLink}
                    onChange={(e) => setRegistrationLink(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {((resource.resource.formatType.title !== "Self-paced" &&
              formatType !== "Self-paced") ||
              (resource.resource.formatType.title === "Self-paced" &&
                formatType !== "" &&
                formatType !== "Self-paced")) && (
              <div className="col">
                <div className="field">
                  <label className="label">Virtual Meeting Link</label>
                  <div className="control">
                    <input
                      className="input"
                      name="virtualMeetingLink"
                      type="url"
                      placeholder="Virtual Meeting Link"
                      defaultValue={resource.resource.virtualMeetingLink}
                      onChange={(e) => setVirtualMeetingLink(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {currentUser.isSuperuser && (
            <div className="row mt-2 mb-2">
              <div className="col-md border">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkboxKL2"
                      style={{ color: "#5cb85c" }}
                      color={"secondary"}
                      disabled={traExclusive === true || maExclusive === true}
                      defaultChecked={resource.resource.kl2exclusive}
                      onChange={(e) => setKl2Exclusive(e.target.checked)}
                    />
                  }
                  label={"KL2 Exclusive"}
                />
              </div>
              <div className="col-md border">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkboxMA"
                      style={{ color: "#5cb85c" }}
                      color={"secondary"}
                      disabled={traExclusive === true || kl2Exclusive === true}
                      defaultChecked={resource.resource.maexclusive}
                      onChange={(e) => setMaExclusive(e.target.checked)}
                    />
                  }
                  label={"MA Exclusive"}
                />
              </div>
              <div className="col-md border">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkboxTRA"
                      style={{ color: "#5cb85c" }}
                      color={"secondary"}
                      disabled={kl2Exclusive === true || maExclusive === true}
                      defaultChecked={resource.resource.traexclusive}
                      onChange={(e) => setTraExclusive(e.target.checked)}
                    />
                  }
                  label={"TRA Exclusive"}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <div className="field">
                <label className="required">Tracks</label>
                {tracksMulti()}
              </div>
            </div>

            <div className="col">
              <div className="field">
                <label className="required">Communities</label>
                {communitiesMulti()}
              </div>
            </div>

            <div className="col">
              <div className="field">
                <label className="required">Competencies</label>
                {competenciesMulti()}
              </div>
            </div>
          </div>
          {currentUser.isSuperuser && (
            <div className="row mt-4 mb-2">
              <div className="col-md ">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkboxKL2"
                      style={{ color: "#5cb85c" }}
                      color={"secondary"}
                      defaultChecked={resource.resource.approved}
                      onChange={(e) => setApproved(e.target.checked)}
                    />
                  }
                  label={"Approved"}
                />
              </div>
            </div>
          )}
          {currentUser.isSuperuser && (
            <div className="row mt-4 mb-2">
              <div className="col-md ">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkboxKL2"
                      style={{ color: "#5cb85c" }}
                      color={"secondary"}
                      defaultChecked={resource.resource.alwaysInclude}
                      onChange={(e) => setAlwaysInclude(e.target.checked)}
                    />
                  }
                  label={"Always Include in Newsletter"}
                />
              </div>
            </div>
          )}
          <br />
          <div className="field">
            <div className="control">
              <button className="button is-link">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditResource;
