import React, { useState, useContext } from "react";
import RRuleGenerator from "react-rrule-generator";
import { Mutation, Query } from "react-apollo";

import { useAlert } from "react-alert";
import { gql } from "apollo-boost";
import { RESOURCES_QUERY, ALL_REGIONS_QUERY } from "../Queries";
import { UserContext } from "../App";
import { UPCOMING_TRACK_RESOURCES } from "./UpcomingTrack";
import history from "./History";
// import Multiselect from "react-widgets/lib/Multiselect";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import "draft-js/dist/Draft.css";

const AddResource = () => {
  const userHasPermission = useContext(UserContext).userHasPermission;
  const currentUser = useContext(UserContext).user;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [speaker, setSpeaker] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [program, setProgram] = useState("");
  const [formatType, setFormatType] = useState("");
  const [nurseInterest] = useState(false);
  const [hyperlink, setHyperlink] = useState("");
  const [registrationLink, setRegistrationLink] = useState("");
  const [virtualMeetingLink, setVirtualMeetingLink] = useState("");
  const [facultyLeader] = useState("");
  const [rrule, setRrule] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [tracks] = useState([]);
  const [communities] = useState([]);
  const [competencies] = useState([]);
  const [kl2Exclusive, setKl2Exclusive] = useState(false);
  const [maExclusive, setMaExclusive] = useState(false);
  const [traExclusive, setTraExclusive] = useState(false);
  const [icsToggle, setIcsToggle] = useState(true);
  const [checked, setChecked] = useState(false);
  const [alwaysInclude, setAlwaysInclude] = useState(false);
  const [region, setRegion] = useState("");
  const alert = useAlert();
  
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const tracksMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.tracks.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxTrack"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={true}
                      disabled={kl2Exclusive || maExclusive || traExclusive}
                      value={track.id}
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const communitiesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.communities
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((track) => {
            return (
              <Accordion expanded={false} key={track.id}>
                <AccordionSummary>
                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        id="checkboxCommunity"
                        style={{ color: "#5cb85c" }}
                        value={track.id}
                        disabled={kl2Exclusive || maExclusive || traExclusive}
                        color={"secondary"}
                      />
                    }
                    label={track.name}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{track.description}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          });
      }}
    </Query>
  );

  const competenciesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.competencies.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCompetency"
                      style={{ color: "#5cb85c" }}
                      disabled={kl2Exclusive || maExclusive || traExclusive}
                      value={track.id}
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const displayRegions = () => (
    <Query query={ALL_REGIONS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.allRegions.map((region) => {
          return (
            <option key={region.name} value={region.name}>
              {region.name}
            </option>
          );
        });
      }}
    </Query>
  );

  //  const regionsMulti = () => (
  //   <Query query={ALL_REGIONS_QUERY}>
  //     {({ data, loading, error }) => {
  //       if (loading) return "Loading...";
  //       if (error) return `Error! ${error.message}`;
  //       return (
  //         <Multiselect
  //           required={true}
  //           data={data.allRegions}
  //           textField="name"
  //           valueField="id"
  //           onChange={(value) => {
  //             setRegion({ value });
  //           }}
  //         />
  //       );
  //     }}
  //   </Query>
  // );

  const displayFormats = () => (
    <Query query={ALL_FORMATS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.allFormats.map((format) => {
          return (
            <option key={format.title} value={format.title}>
              {format.title}
            </option>
          );
        });
      }}
    </Query>
  );

  const displayPrograms = () => (
    <Query query={ALL_PROGRAMS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.allPrograms.map((program) => {
          return (
            <option key={program.title} value={program.title}>
              {program.title}
            </option>
          );
        });
      }}
    </Query>
  );

  const handleSubmit = async (event, createResource) => {
    try {
      event.preventDefault();
      var checks = document.getElementsByTagName("input");
      var counterTrack = 0;
      var counterCommunity = 0;
      var counterCompetency = 0;
      for (let i = 0; i < checks.length; i++) {
        if (checks[i].id === "checkboxTrack") {
          if (checks[i].checked) {
            tracks.push(checks[i].value);
            counterTrack++;
          }
        } else if (checks[i].id === "checkboxCommunity") {
          if (checks[i].checked) {
            communities.push(checks[i].value);
            counterCommunity++;
          }
        } else if (checks[i].id === "checkboxCompetency") {
          if (checks[i].checked) {
            competencies.push(checks[i].value);
            counterCompetency++;
          }
        }
      }
      if (
        kl2Exclusive === false &&
        maExclusive === false &&
        traExclusive === false
      ) {
        if (counterTrack === 0) {
          alert.show("Please select at least one Translational Track", {timeout: 5000, position: 'top center', type: 'info'});
          return;
        } else if (counterCommunity === 0) {
          alert.show("Please select at least one Learning Community", {timeout: 5000, position: 'top center', type: 'info'});
          return;
        } else if (counterCompetency === 0) {
          alert.show("Please select at least one Research Competency", {timeout: 5000, position: 'top center', type: 'info'});
          return;
        }
      } else {
        tracks.length = 0;
        communities.length = 0;
        competencies.length = 0;
      }
      if (description.length < 12) {
        alert.show("Please fill in the Description field", {timeout: 5000, position: 'top center', type: 'info'});
        return;
      }
      await createResource({
        variables: {
          title,
          description,
          location,
          speaker,
          contactName,
          contactEmail,
          program,
          formatType,
          nurseInterest,
          hyperlink,
          registrationLink,
          virtualMeetingLink,
          facultyLeader,
          rrule,
          tracks,
          communities,
          competencies,
          startDate,
          endDate,
          expirationDate,
          kl2Exclusive,
          maExclusive,
          traExclusive,
          icsToggle,
          alwaysInclude,
          //  region:
          //   region.value != undefined
          //     ? region.value.map((region) => region.id)
          //     : [],
          region,
        },
      });
    // history.goBack();
      history.push("allresources");
      alert.show('Resource successfully created!', {timeout: 5000, position: 'top center', type: 'success'});
    }
      
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
      
  };
  if (userHasPermission) {
    return (
      <Mutation
        mutation={CREATE_RESOURCE_MUTATION}
        refetchQueries={() => [
          { query: RESOURCES_QUERY, UPCOMING_TRACK_RESOURCES },
        ]}
      >
        {(createResource, { loading, error }) => {
          if (loading)
            return (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            );
          if (error) return `Error! ${error.message}`;

          return (
            <div className="container">
              <h1 className="page-title">Add Resource</h1>
              <div>
                Fields marked with <label className="required"></label> are
                required
              </div>
              <form onSubmit={(event) => handleSubmit(event, createResource)}>
                <div className="field">
                  <label className="required">Title</label>
                  <div className="control">
                    <input
                      required
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="required">Description</label>
                  <div className="control">
                    {/* <div className="editorContainer">
                    <Editor
                      apiKey="u2y7ryse0yw8eg5twnzt25xl59nwvc4ns7e458v9jag5xcji"
                      initialValue=""
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | \  
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help",
                      }}
                      onEditorChange={handleEditorChange(this)}
                    />
                  </div> */}
                    <ReactQuill
                      required
                      theme="snow"
                      onChange={setDescription}
                      modules={modules}
                    />
                    {/* <textarea
                    className="textarea"
                    name="description"
                    rows="10"
                    placeholder="Description here..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                  </div>
                </div>
                {/* <div className="field">
                <label className="label">Program</label>
                <div className="control">
                  <input
                    className="input"
                    name="programe"
                    type="text"
                    placeholder="Program"
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                  />
                </div>
              </div> */}
                {/* <div className="field">
                <label className="label">Format</label>
                <div className="control">
                  <input
                    className="input"
                    name="formatType"
                    type="text"
                    placeholder="Format"
                    value={formatType}
                    onChange={(e) => setFormatType(e.target.value)}
                  />
                </div>
              </div> */}
                <div className="row">
                  <div className="col">
                    <div className="field">
                      <label className="label required">Program</label>
                      <div className="control">
                        <select
                          className="form-control"
                          required
                          name="format"
                          id="format"
                          onChange={(e) => {
                            setProgram(e.target.value);
                          }}
                        >
                          <option
                            value=""
                            placeholder="Program"
                            selected
                          ></option>
                          {displayPrograms()}
                        </select>
                        {/* <button
                        type="button"
                        className="btn btn-sm"
                        style={{
                          backgroundColor: "transparent",
                          float: "right",
                          paddingRight: "5px",
                        }}
                        onClick={() => handleShow()}
                      >
                        <i
                          className="fa fa-plus mt-1"
                          style={{
                            color: "green",
                            float: "right",
                            paddingRight: "5px",
                          }}
                        />
                      </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="label">Speaker</label>
                      <div className="control">
                        <input
                          className="input"
                          name="locatspeakerion"
                          type="text"
                          placeholder="Speaker"
                          value={speaker}
                          onChange={(e) => setSpeaker(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="label required">Contact Name</label>
                      <div className="control">
                        <input
                          className="input"
                          name="contactName"
                          type="text"
                          placeholder="Contact Name"
                          value={contactName}
                          onChange={(e) => setContactName(e.target.value)}
                          required
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="label required">Contact Email</label>
                      <div className="control">
                        <input
                          className="input"
                          name="contactEmail"
                          type="text"
                          placeholder="Contact Email"
                          value={contactEmail}
                          onChange={(e) => setContactEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="label required">Format</label>
                      <div className="control">
                        <select
                          className="form-control"
                          required
                          name="format"
                          id="format"
                          onChange={(e) => {
                            setFormatType(e.target.value);
                            if (e.target.value === 'Self-paced'){
                              setRrule("");
                              setChecked(false);
                              setStartDate();
                              setEndDate();
                            }
                          }}
                        >
                          <option
                            value=""
                            placeholder="Format"
                            selected
                          ></option>
                          {displayFormats()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {(formatType.includes("Instructor-led, classroom") ||
                  formatType.includes("Instructor-led, hybrid")) && (
                  <div className="row">
                    <div className="col">
                      <label className="label required">Region</label>
                      <div className="control">
                        {/* <select
                    className="form-control"
                    name="region"
                    id="region"
                    onChange={(e) => {
                      setRegion(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="" placeholder="Region" selected></option>
                    {displayRegions()}
                  </select> */}
                        {/* {regionsMulti()} */}
                        <select
                          className="form-control"
                          required
                          name="format"
                          id="format"
                          onChange={(e) => {
                            setRegion(e.target.value);
                          }}
                        >
                          <option
                            value=""
                            placeholder="Format"
                            selected
                          ></option>
                          {displayRegions()}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <label className="label required">Location</label>
                      <div className="control">
                        <input
                          required
                          className="input"
                          name="location"
                          type="text"
                          placeholder="Location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label className="label required">Time Zone</label>
                      <div className="control">
                        <select
                          className="form-control"
                          required
                          name="format"
                          id="format"
                        >
                          <option value="est" selected>
                            EST
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="field">
                <label className="label">Is this of interest to nurses?</label>
                <div className="control">
                  <input
                    type="checkbox"
                    name="toggle"
                    onChange={(e) => setNurseInterest(e.target.value)}
                  />
                </div>
              </div> */}
                {(formatType.includes("Instructor-led, classroom") ||
                  formatType.includes("Instructor-led, hybrid") ||
                  formatType.includes("Instructor-led, online")) && (
                  <div>
                    <div className="row">
                      <div className="col">
                        <label className="label required">Start Time</label>

                        <input
                          className="input"
                          required
                          name="startDate"
                          type="datetime-local"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        {/* </div> */}
                      </div>
                      {/* <div className="row"> */}
                      <div className="col">
                        <label className="label required">End Time</label>
                        <input
                          className="input"
                          required
                          name="endDate"
                          type="datetime-local"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      <div className="col">
                        <label className="label">Expiration Date</label>
                        <input
                          className="input"
                          name="expirationDate"
                          type="datetime-local"
                          onChange={(e) => setExpirationDate(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* // )} */}
                    <br />
                    {/* // {formatType.includes("Instructor") && ( */}
                    <div className="row">
                      <div className="col">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="checkboxRecur"
                              style={{ color: "#5cb85c" }}
                              color={"secondary"}
                              disabled={traExclusive || kl2Exclusive}
                              onChange={(e) => setChecked(!checked)}
                            />
                          }
                          label={"Will this event recur?"}
                        />
                      </div>
                      {/* <div className="col">
                      <div className="mb-3">
                        Will this event recur?{" "}
                        <input
                          type="checkbox"
                          onChange={(e) => setChecked(!checked)}
                        />
                      </div>
                    </div> */}
                      <div className="col">
                        <div className="row form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            onChange={(e) => setIcsToggle(!icsToggle)}
                            defaultChecked
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            Calendar Event Link
                          </label>
                        </div>
                        <div className="row form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            onChange={(e) => setIcsToggle(!icsToggle)}
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            Registration Link
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {checked && (
                  <div className="field mb-3">
                    <RRuleGenerator
                      onChange={(e) => {
                        setRrule(e);
                        console.log(e);
                      }}
                    />
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <div className="field">
                      <label className="label">Event Info</label>
                      <div className="control">
                        <input
                          className="input"
                          name="hyperlink"
                          type="url"
                          placeholder="Hyperlink"
                          value={hyperlink}
                          onChange={(e) => setHyperlink(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="label">Registration Link</label>
                      <div className="control">
                        <input
                          className="input"
                          name="registrationLink"
                          type="url"
                          placeholder="Registration Link"
                          value={registrationLink}
                          onChange={(e) => setRegistrationLink(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {(formatType.includes("Instructor-led, classroom") ||
                    formatType.includes("Instructor-led, hybrid") ||
                    formatType.includes("Instructor-led, online")) && (
                    <div className="col">
                      <div className="field">
                        <label className="label">Virtual Meeting Link</label>
                        <div className="control">
                          <input
                            className="input"
                            name="virtualMeetingLink"
                            type="url"
                            placeholder="Virtual Meeting Link"
                            value={virtualMeetingLink}
                            onChange={(e) =>
                              setVirtualMeetingLink(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="field">
                <label className="label">Image</label>
                <div className="control-file">
                  <input
                    type="file"
                    className="file"
                    accept="image/*"
                    onChange={setImage}
                  />
                </div>
              </div> */}
                {currentUser.isSuperuser && (
                  <div className="row mt-2 mb-2">
                    <div className="col-md border">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="checkboxKL2"
                            style={{ color: "#5cb85c" }}
                            color={"secondary"}
                            disabled={traExclusive || maExclusive}
                            label={"KL2 Exclusive"}
                            onChange={(e) => setKl2Exclusive(e.target.checked)}
                          />
                        }
                        label={"KL2 Exclusive"}
                      />
                    </div>
                    <div className="col-md border">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="checkboxMA"
                            style={{ color: "#5cb85c" }}
                            color={"secondary"}
                            disabled={traExclusive || kl2Exclusive}
                            onChange={(e) => setMaExclusive(e.target.checked)}
                          />
                        }
                        label={"MA Exclusive"}
                      />
                    </div>
                    <div className="col-md border">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="checkboxTRA"
                            style={{ color: "#5cb85c" }}
                            color={"secondary"}
                            disabled={kl2Exclusive || maExclusive}
                            onChange={(e) => setTraExclusive(e.target.checked)}
                          />
                        }
                        label={"TRA Exclusive"}
                      />
                    </div>
                  </div>
                )}
                <div className="row mb-4">
                  <div className="col">
                    <div className="field">
                      <label className="required">Translational Tracks</label>
                      {tracksMulti()}
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="required">Learning Communities</label>
                      {communitiesMulti()}
                    </div>
                  </div>
                  <div className="col">
                    <div className="field">
                      <label className="required">Research Competencies</label>
                      {competenciesMulti()}
                    </div>
                  </div>
                </div>
                {currentUser.isSuperuser && (
                  <div className="row mt-4 mb-2">
                    <div className="col-md ">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="checkboxKL2"
                            style={{ color: "#5cb85c" }}
                            color={"secondary"}
                            value={alwaysInclude}
                            onChange={(e) => setAlwaysInclude(e.target.checked)}
                          />
                        }
                        label={"Always Include in Newsletter"}
                      />
                    </div>
                  </div>
                )}
                {/* <div className="field">
                <label className="label">Organizer</label>
                <input
                  className="input"
                  name="formatType"
                  type="text"
                  placeholder="Organizer"
                  value={facultyLeader}
                  onChange={(e) => setFacultyLeader(e.target.value)}
                />
              </div> */}
                <br />
                <div className="field">
                  <div className="control">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        //notify.show("Resource was created successfully", "success");
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              {/* {console.log(show)}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Add New Program</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="field">
                  <label className="required">Title</label>
                  <div className="control">
                    <input
                      required
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Program"
                      // value={programTitle}
                      // onChange={(e) => setProgramTitle(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  className="button is-link"
                  onClick={(e) => {
                    //alert("Resource was created successfully");
                    //notify.show("Resource was created successfully", "success");
                  }}
                >
                  Submit
                </button>
              </Modal.Body>
            </Modal> */}
            </div>
          );
        }}
      </Mutation>
    );
  } else {
    return (
      <div className="text-center mt-2">
        You do not have the appropriate permission to view this page
      </div>
    );
  }
};

const CREATE_RESOURCE_MUTATION = gql`
  mutation (
    $title: String!
    $description: String
    $location: String
    $speaker: String
    $contactName: String
    $contactEmail: String
    $program: String
    $formatType: String
    $nurseInterest: Boolean
    $hyperlink: String!
    $registrationLink: String!
    $virtualMeetingLink: String!
    $tracks: [String]!
    $communities: [String]!
    $competencies: [String]!
    $facultyLeader: String!
    $rrule: String
    $startDate: DateTime
    $endDate: DateTime
    $expirationDate: DateTime
    $region: String
    $traExclusive: Boolean
    $maExclusive: Boolean
    $kl2Exclusive: Boolean
    $icsToggle: Boolean
    $alwaysInclude: Boolean
  ) {
    createResource(
      input: {
        title: $title
        description: $description
        location: $location
        speaker: $speaker
        contactName: $contactName
        contactEmail: $contactEmail
        program: $program
        formatType: $formatType
        nurseInterest: $nurseInterest
        hyperlink: $hyperlink
        registrationLink: $registrationLink
        virtualMeetingLink: $virtualMeetingLink
        tracks: $tracks
        communities: $communities
        competencies: $competencies
        facultyLeader: $facultyLeader
        rrule: $rrule
        startDate: $startDate
        endDate: $endDate
        expirationDate: $expirationDate
        region: $region
        traExclusive: $traExclusive
        maExclusive: $maExclusive
        kl2Exclusive: $kl2Exclusive
        icsToggle: $icsToggle
        alwaysInclude: $alwaysInclude
      }
    ) {
      resource {
        title
        description
      }
    }
  }
`;

export const TRACKS_QUERY = gql`
  {
    tracks {
      name
      description
      id
    }
    communities {
      name
      description
      id
      orderIndex
    }
    competencies {
      name
      description
      id
    }
  }
`;

export const ALL_FORMATS_QUERY = gql`
  {
    allFormats {
      title
      description
      id
    }
  }
`;

export const ALL_PROGRAMS_QUERY = gql`
  {
    allPrograms {
      title
      description
      id
    }
  }
`;

export default AddResource;
