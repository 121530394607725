import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { onError } from "apollo-link-error";
//import Notifications from "react-notify-toast";

import { ApolloProvider } from "react-apollo";
// import { useQuery, useMutation } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";

//import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-boost";
import { offsetLimitPagination } from "@apollo/client/utilities";

import "./index.css";
import "../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
// import App from "./App";
// import Auth from "./Auth";
import Temp from "./Temp";
// import { USER_QUERY } from "./Queries";
//import * as serviceWorker from "./serviceWorker";

require("dotenv").config();

// const errorLink = onError(({ graphQLErrors }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message }) => notify.show(message, "error"));
// });

/*const httpLink = createHttpLink({
  uri: "//localhost:8000/graphql/"
  //credentials: "include"
});*/

//const link = ApolloLink.from([errorLink]);

// const { loading, error, data } = useQuery(USER_QUERY);
// let loggedIn = false;
// if (data != null) {
//   loggedIn = true;
// }

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_URI}`,
  link: ApolloLink.from([
    onError(({ graphQLErrors }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message }) => console.log(`${message}`));
    }),
  ]),
  fetchOptions: {
    credentials: "include",
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: offsetLimitPagination(),
        },
      },
    },
  }),
  request: (operation) => {
    const token = localStorage.getItem("authToken") || "";
    operation.setContext({
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  },
  clientState: {
    defaults: {
      isLoggedIn: !!localStorage.getItem("authToken"),
    },
  },
});

// const IS_LOGGED_IN = gql`
//   query {
//     isLoggedIn @client
//   }
// `;

/*const client = new ApolloClient({
  link,
  clientState: {
    defaults: {
      isLoggedIn: !!localStorage.getItem("authToken")
    }
  },
  cache: new InMemoryCache()
});*/

ReactDOM.render(
  <ApolloProvider client={client}>
    <Temp />
  </ApolloProvider>,
  document.getElementById("root")
  /*<ApolloProvider client={client}>
    <Notifications />
    <App />
  </ApolloProvider>,
  document.getElementById("root")*/
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
