import React, { useState } from "react";
import { useHistory} from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
// import { UserContext } from "../App";
// import OccurrenceDetail from "./OccurrenceDetail";
import "../App.css";
import Modal from "react-bootstrap/Modal";

const TreeCalendar = () => {
  const [show, setShow] = useState(false);
  const [evt, setEvt] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const localizer = momentLocalizer(moment);
  // const currentUser = useContext(UserContext).user;
  const history = useHistory();
  let events = [];
  let id = 0;
  const { loading, error, data } = useQuery(CALENDAR_QUERY);
  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (error) return `Error! ${error.message}`;

  // data.allResourcesNextOccurrences.map((occurrence) => {
  //   let desc = "";
  //   if (occurrence.resourceField.description != null && occurrence.resourceField.description != "") {
  //     desc = occurrence.resourceField.description.replace(/<[^>]+>/g, '');
  //   }

  //     events.push({
  //       id: id,
  //       title: occurrence.resourceField.title,
  //       description: desc,
  //       start: occurrence.nextDate,
  //       end: occurrence.endDate,
  //       resourceID: occurrence.resourceField.id,
  //       rrule: occurrence.resourceField.rrule,
  //     });
  //   id += 1;
  // });

  data.allResourcesNextOccurrences.forEach(occurrence => {
    let desc = "";
    if (occurrence.resourceField.description !== null && occurrence.resourceField.description !== "") {
      desc = occurrence.resourceField.description.replace(/<[^>]+>/g, '');
    }

      events.push({
        id: id,
        title: occurrence.resourceField.title,
        description: desc,
        start: occurrence.nextDate,
        end: occurrence.endDate,
        resourceID: occurrence.resourceField.id,
        rrule: occurrence.resourceField.rrule,
      });
    id += 1;
  });

  return (
    <div className="container mt-3">
      <Calendar
        localizer={localizer}
        events={events}
        // scrollToTime={new Date().setHours(7)}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 625 }}
        views={["month"]}
        defaultView={"month"}
        onSelectEvent={(event) => {
          if (event.rrule === null || event.rrule === "") {
            history.push(`resourcedetail/${event.resourceID}`);
          } else {
            setEvt(event);
            handleShow();
          }
        }}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>{evt ? evt.title : ""}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Start:{" "}
            {evt ? moment(evt.start).format("MMMM D, YYYY hh:mm A") : ""}
          </div>
          <div>
            End: {evt ? moment(evt.end).format("MMMM D, YYYY hh:mm A") : ""}
          </div>
          <div className="mt-2">{evt ? evt.description : ""}</div>
          {/* {currentUser.isSuperuser && (
            <Link
              to={{
                pathname: "/editoccurrence",
                state: { event: evt },
              }}
            >
              Edit
            </Link>
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

//   return (
//     <div className="container mt-3">
//       <Calendar
//         localizer={localizer}
//         events={events}
//         scrollToTime={new Date().setHours(7)}
//         startAccessor="start"
//         endAccessor="end"
//         style={{ height: 625 }}
//         views={["day", "work_week", "week", "month", "agenda"]}
//         defaultView={"month"}
//         onSelectEvent={(event) => {
//           // history.push(`resourcedetail/${event.resourceID}`)
//           // console.log(event.start)
//           // <OccurrenceDetail event={event} />

//           //history.push({ pathname: `occurrence`, state: { event: event } })
//           setEvt(event);
//           handleShow();

//           // history.push("occurrence/", { event: event });
//           // <Redirect to={{ pathname: "occurrence", state: { event: event } }} />
//         }}
//       />
//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             <h4>{evt ? evt.title : ""}</h4>
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div>
//             Start:{" "}
//             {evt ? moment(evt.start).format("MMMM DD, YYYY hh:mm A") : ""}
//           </div>
//           <div>
//             End: {evt ? moment(evt.end).format("MMMM DD, YYYY hh:mm A") : ""}
//           </div>
//           <div>{evt ? evt.description : ""}</div>
//           {currentUser.isSuperuser && (
//             <Link
//               to={{
//                 pathname: "/editoccurrence",
//                 state: { event: evt },
//               }}
//             >
//               Edit
//             </Link>
//           )}
//         </Modal.Body>
//       </Modal>
//       {/* <FullCalendar
//         defaultView="dayGridMonth"
//         plugins={[dayGridPlugin, rrulePlugin]}
//         events={events}
//         height={650}
//         eventClick={event => history.push(`resourcedetail/${event.resourceID}`)}
//       /> */}
//     </div>
//   );
// };

const CALENDAR_QUERY = gql`
{
  allResourcesNextOccurrences{
    nextDate
    endDate
    resourceField{
      title
      description
      id
      rrule
    }
  }
}
`;

export default TreeCalendar;

// import React, { useContext } from "react";
// import { useHistory } from "react-router-dom";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import { useQuery } from "@apollo/react-hooks";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
// import rrulePlugin from "@fullcalendar/rrule";
// import moment from "moment";
// import { gql } from "apollo-boost";
// import { UserContext } from "../App";
// import "../App.css";

// const TreeCalendar = () => {
//   const localizer = momentLocalizer(moment);
//   // const currentUser = useContext(UserContext);
//   let events = [];
//   let id = 0;

//   const { loading, error,  data } = useQuery(CALENDAR_QUERY);
//   if (loading)
//     return (
//       <div className="spinner-border" role="status">
//         <span className="sr-only">Loading...</span>
//       </div>
//     );
//   if (error) return `Error! ${error.message}`;

//   data.allResources.edges.map(subscription => {
//     if (subscription.node.rrule !== "") {

//       events.push({
//         id,
//         resourceID: subscription.node.id,
//         title: subscription.node.title,
//         rrule: `DTSTART:${subscription.node.startDate.replace(
//           /-|:/g,
//           ""
//         )}\n${subscription.node.rrule}`,
//         duration:
//           subscription.node.endTime - subscription.node.startTime
//       });
//       id++;
//     } else {
//       let title = subscription.node.title;
//       let start = new Date(
//         moment(
//           subscription.node.startDate + subscription.node.startTime,
//           "YYYY-MM-DDLT"
//         )
//       );
//       let end = new Date(
//         moment(
//           subscription.node.endDate + subscription.node.endTime,
//           "YYYY-MM-DDLT"
//         )
//       );
//       let resourceID = subscription.node.id;
//       events.push({ id, title, start, end, resourceID });
//       id++;
//     }
//   });

//   // const history = useHistory();

//   return (
//     <div className="container">
//       <FullCalendar
//         defaultView="dayGridMonth"
//         plugins={[dayGridPlugin, rrulePlugin]}
//         events={events}
//         height={650}
//         // eventClick={event => history.push(`resourcedetail/${event.resourceID}`)}
//       />
//     </div>
//   );
// };

// const CALENDAR_QUERY = gql`
// {
//   allResources {
//     edges {
//       node {
//         id
//         title
//         description
//         program
//         formatType
//         nurseInterest
//         hyperlink
//         startDate
//         endDate
//         facultyLeader
//         rrule
//       }
//     }
//   }
// }
// `;

// export default TreeCalendar;
