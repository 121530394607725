import React, { useState, useContext } from "react";
import { Mutation, Query } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import history from "./History";
import Multiselect from "react-widgets/lib/Multiselect";
// import { TRACKS_QUERY } from "./StepCommunities";
import { ALL_REGIONS_QUERY } from "../Queries";
import {USER_QUERY} from "../App"
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Checkbox,
//   FormControlLabel,
// } from "@material-ui/core";
// import { ExpandMore } from "@material-ui/icons";
import { UserContext } from "../App";
import { useAlert } from "react-alert";

const StepRegion = () => {
  const [region, setRegion] = useState("");
  const alert = useAlert();
  
  const currentUser = useContext(UserContext).user;

  const displayRegions = () => (
    <Query query={ALL_REGIONS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return (
          <Multiselect
            required={true}
            data={data.allRegions}
            defaultValue={data.allRegions}
            textField="name"
            valueField="id"
            onChange={(value) => {
              setRegion({ value });
            }}
          />
        );
      }}
    </Query>
  );

  const { loading, error, data } = useQuery(STEP_USER_QUERY);
  if (loading) return 0;
  if (error) return 0;

  const handleSubmit = async (event, updateUserTracks) => {
    try {
      event.preventDefault();

      await updateUserTracks({
        variables: {
          region:
            region !== ""
              ? region.value.map((region) => region.id)
              : data.allRegions.map((region) => region.id),
        },
      });
      history.push("/");
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PROFILE_MUTATION_TRACKS}
      refetchQueries={() => [{ query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } }]}
    >
      {(updateUserTracks, { loading, error }) => {
        if (loading)
          return (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          );
        if (error) return `Error! ${error.message}`;
        return (
          <div className="container">
            <div className="mt-3">
              Thanks, {currentUser.firstName}! Now you can select a region. Some
              of the content in TREE is region-specific, so this setting will
              help you filter out things that are not relevant to you.
            </div>
            <div className="mt-4">
              <b>
                <div className="text-success">Region</div>
              </b>
            </div>
            <form onSubmit={(event) => handleSubmit(event, updateUserTracks)}>
              <div className="field mt-3">
                <div className="control">{displayRegions()}</div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <Link to="/competencies" className="button is-link link-underline link-underline-opacity-0">
                    Go Back
                  </Link>
                </div>
                <div className="col">
                  <div className="text-end">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        //alert("Resource was created successfully");
                        //notify.show("Resource was created successfully", "success");
                      }}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
};

// const TRACKS_QUERY = gql`
//   {
//     tracks {
//       name
//       id
//     }
//     communities {
//       name
//       id
//     }
//     competencies {
//       name
//       id
//     }
//     user {
//       tracks {
//         id
//         name
//       }
//       communities {
//         id
//         name
//       }
//       competencies {
//         id
//         name
//       }
//     }
//   }
// `;

export const STEP_USER_QUERY = gql`
  {
    user {
      username
      id
      tracks {
        id
        name
      }
    }
    allRegions{
      name
      id
    }
  }
`;

const UPDATE_PROFILE_MUTATION_TRACKS = gql`
  mutation($region: [String]) {
    updateUserRegion(input: { region: $region }) {
      user {
        id
        username
      }
    }
  }
`;

export default StepRegion;
