exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!@fullcalendar/core/main.css"), "");
exports.i(require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!@fullcalendar/daygrid/main.css"), "");
exports.i(require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!@fullcalendar/timegrid/main.css"), "");
exports.i(require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!react-rrule-generator/build/styles.css"), "");

// Module
exports.push([module.id, ".App-header {\n  background-color: #222;\n  color: white;\n  font-weight: bold;\n}\n​ .navbar-item {\n  color: white;\n}\n\n​ .App-title {\n  font-size: 1.5em;\n}\n​ .App-intro {\n  font-size: large;\n}\n​ .m-t-20 {\n  margin-top: 20px;\n}\n​ .card {\n  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);\n}\n​ .page-title {\n  font-size: 2rem;\n  margin-bottom: 20px;\n}\n​ .newnote-page {\n  height: calc(100vh - 52px);\n  width: 60%;\n}\n​ .field:not(:last-child) {\n  margin-bottom: 2.5rem;\n}\n​ .card button {\n  border: none;\n  color: #3273dc;\n  cursor: pointer;\n  text-decoration: none;\n  font-size: 16px;\n}\n.card button:hover {\n  color: #363636;\n}\n\n.container {\n  max-width: 80%;\n}\n\n.container-home {\n  max-width: 90%;\n}\n\nbody {\n  background-color: #f5f5f5;\n}\n\n.btn.btn-primary.active {\n  background-color: #070a5e !important;\n}\n\n.btn.active {\n  outline: 10;\n}\n\n.col-9.col-sm-6 {\n  visibility: hidden !important;\n}\n\n.col-9.col-sm-6:after {\n  content: \"occurrences\" !important;\n  visibility: visible !important;\n  position: absolute !important;\n  bottom: 1px !important;\n}\n\n.ql-bubble .ql-editor {\n  /* background-color: #ffffff; */\n  background-color: #f5f5f5;\n  min-height: 100px !important;\n}\n\n.ql-snow .ql-editor {\n  background-color: #ffffff;\n  min-height: 100px !important;\n}\n\na:hover {\n  color: darkgrey!important;\n}\n\na {\n  text-decoration: none;\n}", ""]);

