import React from "react";
import "./index.css";
import App from "./App";
import Auth from "./Auth";
import { useQuery } from "@apollo/react-hooks";
// import { USER_QUERY } from "./App";
import { gql } from "apollo-boost";

const Temp = () => {
  const { loading, error, data } = useQuery(IS_LOGGED_IN_QUERY);
  if (loading)
    return (
      <div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (error) return `Error! ${error.message}`;
  return <div>{data.user ? <App /> : <Auth />}</div>;
};

export const IS_LOGGED_IN_QUERY = gql`
  {
    user {
      id
      username
    }
  }
`;

export default Temp;
