import React, { useState, useContext } from "react";
import { Mutation, Query } from "react-apollo";
import { useQuery} from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import history from "./History";
// import Multiselect from "react-widgets/lib/Multiselect";
// import { USER_QUERY } from "../App";
import { TRACKS_QUERY } from "./StepCommunities";
// import { ME_QUERY } from "./Profile";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { UserContext } from "../App";
import { useAlert } from "react-alert";

const StepCompetencies = () => {
  const [competencies] = useState([]);

  const currentUser = useContext(UserContext).user;
  const alert = useAlert();

  const competenciesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.competencies.map((track) => {
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCompetency"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.competencies.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const { loading, error, data } = useQuery(STEP_USER_QUERY);
  if (loading) return 0;
  if (error) return 0;

  const handleSubmit = async (event, updateUserCompetencies) => {
    try {
      event.preventDefault();
      var counterCompetencey = 0;
      var checks = document.getElementsByTagName("input");
      for (let i = 0; i < checks.length; i++) {
        if (checks[i].id === "checkboxCompetency") {
          if (checks[i].checked) {
            competencies.push(checks[i].value);
            counterCompetencey++;
          }
        }
      }
      if (counterCompetencey === 0) {
        alert("Please select at least one Research Competency");
        return;
      }
      await updateUserCompetencies({
        variables: {
          competencies:
            competencies !== undefined
              ? competencies
              : data.me.competencies.map((track) => track.id),
        },
      });
      history.push(`/region`);
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PROFILE_MUTATION_COMPETENCIES}
      refetchQueries={() => [{ query: TRACKS_QUERY }]}
      // refetchQueries={() => [{query: TRACKS_QUERY}, {query: USER_QUERY}, {query: ME_QUERY, variables: {id: 1}}]}
    >
      {(updateUserCompetencies, { loading, error }) => {
        if (loading)
          return (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          );
        if (error) return `Error! ${error.message}`;
        return (
          <div className="container">
            <div className="mt-3">
              Okay, {currentUser.firstName}, one final step. We want to
              understand the “Research Competencies” you wish to develop or
              fine-tune. There are a myriad of resources across our institution
              and our desire is to deliver those that interest you. You can
              always go back and update your profile once completed. We’ve added
              some short descriptors and examples of offerings to guide you.
            </div>
            <div className="mt-4">
              <div className="text-success">
                <b>Research Competencies</b>
              </div>
            </div>
            <form
              onSubmit={(event) => handleSubmit(event, updateUserCompetencies)}
            >
              <div className="mt-5">{competenciesMulti()}</div>
              <div className="row mt-5">
                <div className="col">
                  <Link to="/tracks" className="button is-link link-underline link-underline-opacity-0">
                    Go Back
                  </Link>
                </div>
                <div className="col">
                  <div className="text-end">
                    <button
                      className="button is-link"
                      onClick={(e) => {
                        //alert("Resource was created successfully");
                        //notify.show("Resource was created successfully", "success");
                      }}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
};

// const TRACKS_QUERY = gql`
//   {
//     tracks {
//       name
//       id
//     }
//     communities {
//       name
//       id
//     }
//     competencies {
//       name
//       id
//     }
//     user {
//       tracks {
//         id
//         name
//       }
//       communities {
//         id
//         name
//       }
//       competencies {
//         id
//         name
//       }
//     }
//   }
// `;

export const STEP_USER_QUERY = gql`
  {
    user {
      username
      id
      competencies {
        id
        name
      }
    }
  }
`;

const UPDATE_PROFILE_MUTATION_COMPETENCIES = gql`
  mutation($competencies: [String]) {
    updateUserCompetencies(input: { competencies: $competencies }) {
      user {
        id
        username
      }
    }
  }
`;

export default StepCompetencies;
