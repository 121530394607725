import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import history from "./History";
import { Link } from "react-router-dom";
import { ME_QUERY } from "./Profile";
import { USER_QUERY } from "../App";
import "react-widgets/dist/css/react-widgets.css";
import Multiselect from "react-widgets/lib/Multiselect";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useAlert } from "react-alert";

const UpdateProfile = ({ match }) => {
  const { loading, error, data } = useQuery(ME_QUERY, {
    variables: {
      id: match.params.id,
    },
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tracks] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [competencies] = useState([]);
  const [recieveEmail, setRecieveEmail] = useState("false");
  const [region, setRegion] = useState([]);
  const alert = useAlert();
  
  const handleSubmit = async (event, updateUser) => {
    try {
      event.preventDefault();
      var checks = document.getElementsByTagName("input");
      var counterTrack = 0;
      var counterCommunity = 0;
      var counterCompetency = 0;
      for (let i = 0; i < checks.length; i++) {
        if (checks[i].id === "checkboxTrack") {
          if (checks[i].checked) {
            tracks.push(checks[i].value);
            counterTrack++;
          }
        } else if (checks[i].id === "checkboxCommunity") {
          if (checks[i].checked) {
            communities.push(checks[i].value);
            counterCommunity++;
          }
        } else if (checks[i].id === "checkboxCompetency") {
          if (checks[i].checked) {
            competencies.push(checks[i].value);
            counterCompetency++;
          }
        }
      }
      if (counterTrack === 0) {
        // notify.show("Please select at least one Track");
        alert.show("Please select at least one Translational Track", {timeout: 5000, position: 'top center', type: 'info'});
      } else if (counterCommunity === 0) {
        alert.show("Please select at least one Learning Community", {timeout: 5000, position: 'top center', type: 'info'});
        setCommunities([]);
        return;
      } else if (counterCompetency === 0) {
        alert.show("Please select at least one Research Competency", {timeout: 5000, position: 'top center', type: 'info'});
        return;
      }
      updateUser({
        variables: {
          id: match.params.id,
          firstName: firstName ? firstName : data.me.firstName,
          lastName: lastName ? lastName : data.me.lastName,
          email: email ? email : data.me.email,
          // region: region ? region : data.me.region.name,
          region:
            region.value !== undefined
              ? region.value.map((region) => region.id)
              : data.me.region.map((region) => region.id),
          tracks:
            tracks !== undefined
              ? tracks
              : data.me.tracks.map((track) => track.id),
          communities:
            communities !== undefined
              ? communities
              : data.me.communities.map((track) => track.id),
          competencies:
            competencies !== undefined
              ? competencies
              : data.me.competencies.map((track) => track.id),
          recieveEmail:
            recieveEmail !== "false" ? recieveEmail : data.me.recieveEmail,
        },
      });
      history.goBack();
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  const handleChange = (target, type) => {
    // const options = event.target.options;
    var checks = document.getElementsByTagName("input");
    console.log(checks.length);
    // for (var i = 0; i < options.length; i++) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
    switch (type) {
      case "competency":
        // setCompetencies(value);
        break;
      case "track":
        // setTracks(value);
        break;
      case "community":
        // setCommunities(value);
        break;
      default:
        break;
    }
  };

  const tracksMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.tracks.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxTrack"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.tracks.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                      onChange={() => {
                        handleChange(track.id, "track");
                      }}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const communitiesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.communities.map((track) => {
          return (
            <Accordion expanded={false} key={track.id}>
              <AccordionSummary>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCommunity"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.communities.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                      onChange={() => {
                        handleChange(track.id, "track");
                      }}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const competenciesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.competencies.map((track) => {
          return (
            <Accordion key={track.id}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCompetency"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.competencies.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                      onChange={() => {
                        handleChange(track.id, "track");
                      }}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const regionsMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return (
          <Multiselect
            data={data.allRegions}
            textField="name"
            valueField="id"
            defaultValue={data.user.region}
            onChange={(value) => {
              setRegion({ value });
            }}
          />
        );
      }}
    </Query>
  );

  const [updateUser] = useMutation(UPDATE_PROFILE_MUTATION, {
    refetchQueries: [
      {
        query: ME_QUERY,
        variables: { id: match.params.id },
      },
      { query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } },
    ],
  });

  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (error) return <div>Error</div>;

  return (
    <div className="container m-t-20">
      <form onSubmit={(event) => handleSubmit(event, updateUser)}>
        <div className="text-end">
          <Link className="btn btn-secondary btn-sm mt-4" to={`/communities`}>
            Need Help?
          </Link>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="field">
              <label className="label">First Name</label>
              <div className="control">
                <input
                  className="input"
                  name="firstName"
                  type="test"
                  placeholder="First Name"
                  defaultValue={data.me.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div></div>

          <div className="col">
            <div className="field">
              <label className="label">Last Name</label>
              <div className="control">
                <input
                  className="input"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  defaultValue={data.me.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  name="email"
                  type="email"
                  placeholder="Email"
                  defaultValue={data.me.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col align-self-start">
            <label className="label">Region</label>
            <div className="control">
              {/* <select
                className="form-control"
                name="region"
                id="region"
                value={data.me.region.id}
                onChange={(e) => {
                  setRegion(e.target.value);
                }}
              >
                {console.log(data.me.region.name)}
                {displayRegions()}
              </select> */}
              {regionsMulti()}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="field">
              <label className="label">Tracks</label>
              {tracksMulti()}
            </div>
          </div>
          <div className="col">
            <div className="field">
              <label className="label">Communities</label>
              {communitiesMulti()}
            </div>
          </div>
          <div className="col">
            <div className="field">
              <label className="label">Competencies</label>
              {competenciesMulti()}
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">
            Would you like to recieve the weekly TREE newsletter?
          </label>
          <div className="control">
            <input
              type="checkbox"
              name="toggle"
              defaultChecked={data.me.recieveEmail}
              onChange={(e) => setRecieveEmail(e.target.checked)}
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-link" onClick={(e) => {}}>
              Update Profile
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const UPDATE_PROFILE_MUTATION = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $tracks: [String]
    $communities: [String]
    $competencies: [String]
    $recieveEmail: Boolean!
    $region: [String]
  ) {
    updateUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        tracks: $tracks
        communities: $communities
        competencies: $competencies
        recieveEmail: $recieveEmail
        region: $region
      }
    ) {
      user {
        id
        username
        firstName
        lastName
        email
        recieveEmail
      }
    }
  }
`;

export const TRACKS_QUERY = gql`
  {
    tracks {
      name
      description
      id
    }
    communities {
      name
      description
      id
    }
    competencies {
      name
      description
      id
    }
    allRegions {
      name
      description
      id
    }
    user {
      id
      tracks {
        id
        name
      }
      communities {
        id
        name
      }
      competencies {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;

export default UpdateProfile;
