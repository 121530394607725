import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import history from "./History";
import { ADD_USER } from "../Queries";
// import { ME_QUERY } from "./Profile";
import { USER_QUERY } from "../App";
import { useAlert } from "react-alert";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tracks, setTracks] = useState(0);
  const [communities, setCommunities] = useState(0);
  const [competencies, setCompetencies] = useState(0);
  const alert = useAlert();
  
  const handleSubmit = async (
    event,
    addUser,
    updateUser,
    tokenAuth,
    client
  ) => {
    try {
      event.preventDefault();
      await addUser({
        variables: {
          username,
          email,
          password
        }
      });
      await handleLogin(tokenAuth, client);
      updateUser({
        variables: {
          tracks: tracks,
          communities: communities,
          competencies: competencies
        }
      });
      history.goBack();
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  const handleLogin = async (tokenAuth, client) => {
    const res = await tokenAuth({ variables: { username, password } });
    localStorage.setItem("authToken", res.data.tokenAuth.token);
    client.writeData({ data: { isLoggedIn: true } });
  };

  const handleChange = (event, type) => {
    const options = event.target.options;
    var value = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    switch (type) {
      case "competency":
        setCompetencies(value);
        break;
      case "track":
        setTracks(value);
        break;
      case "community":
        setCommunities(value);
        break;
      default:
        break;
    }
  };

  //Displays all Track options
  const displayTracks = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.tracks.map(faculty => {
          return (
            <option key={faculty.id} value={faculty.id}>
              {faculty.name}
            </option>
          );
        });
      }}
    </Query>
  );

  //Displays all Community options
  const displayCommunities = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.communities.map(faculty => {
          return (
            <option key={faculty.id} value={faculty.id}>
              {faculty.name}
            </option>
          );
        });
      }}
    </Query>
  );

  //Displays all Competency options
  const displayCompetencies = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.competencies.map(faculty => {
          return (
            <option key={faculty.id} value={faculty.id}>
              {faculty.name}
            </option>
          );
        });
      }}
    </Query>
  );

  const [addUser] = useMutation(ADD_USER);

  const [updateUser] = useMutation(UPDATE_PROFILE_MUTATION, {
    refetchQueries: [{query: USER_QUERY}]
  });

  const [tokenAuth, { client }] = useMutation(LOGIN_MUTATION);

  return (
    <div className="container m-t-20">
      <h1 className="page0title">Create An Account</h1>

      <div className="newnote-page m-t-20">
        <form
          onSubmit={event =>
            handleSubmit(event, addUser, updateUser, tokenAuth, client)
          }
        >
          <div className="field">
            <label className="label">Username</label>
            <div className="control">
              <input
                className="input"
                name="title"
                type="text"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control">
              <input
                className="input"
                name="email"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Translational Tracks</label>
            <select
              multiple
              className="form-control multiple"
              id="sel1"
              onChange={event => handleChange(event, "track")}
            >
              {displayTracks()}
            </select>
          </div>

          <div className="field">
            <label className="label">Learning Communities</label>
            <select
              multiple
              className="form-control multiple"
              id="sel2"
              onChange={event => handleChange(event, "community")}
            >
              {displayCommunities()}
            </select>
          </div>

          <div className="field">
            <label className="label">Research Competencies</label>
            <select
              multiple
              className="form-control multiple"
              id="sel3"
              onChange={event => handleChange(event, "competency")}
            >
              {displayCompetencies()}
            </select>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                name="title"
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className="button is-link"
                disabled={
                  !username.trim() ||
                  !email.trim() ||
                  !password.trim() ||
                  !tracks ||
                  !communities ||
                  !competencies
                }
              >
                Submit
              </button>
            </div>
          </div>
          <Link to={"./"} className="card-footer-item">
            Already Have An Account?
          </Link>
        </form>
      </div>
    </div>
  );
};

const UPDATE_PROFILE_MUTATION = gql`
  mutation($tracks: [Int], $communities: [Int], $competencies: [Int]) {
    updateUser(
      input: {
        tracks: $tracks
        communities: $communities
        competencies: $competencies
      }
    ) {
      user {
        id
      }
    }
  }
`;

const TRACKS_QUERY = gql`
  {
    tracks {
      name
      id
    }
    communities {
      name
      id
    }
    competencies {
      name
      id
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`;

export default Signup;
