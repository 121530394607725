import React, { useState, useContext } from "react";
import { UserContext } from "../App";
import { useMutation } from "@apollo/react-hooks";
import history from "./History";
import { gql } from "apollo-boost";
import { useAlert } from "react-alert";


const Feedback = () => {
  const currentUser = useContext(UserContext).user;
  const [formName, setFormName] = useState(currentUser.firstName +' '+ currentUser.lastName);
  const [formEmail, setFormEmail] = useState(currentUser.email); 
  const [formMessage, setFormMessage] = useState('');
  const alert = useAlert();
  const [createFeedback] = useMutation(CREATE_MESSAGE_MUTATION);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      
      await createFeedback({
          variables: {
              email: formEmail,
              name: formName,
              message: formMessage
          },
        });
      alert.show(`Feedback Sent!`, {timeout: 5000, position: 'top center', type: 'success'});
      history.goBack();
      }
    catch (err){
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
};

return (
    <div className="container">
      <div className="card mt-3">
        <div className="card-body">
          <h4 className="card-title pb-4">Send us your feedback!</h4>
          <form onSubmit={(event) => handleSubmit(event, createFeedback)} className="needs-validation">
            <div className="mb-3">
              <label for="exampleFormControlInput1" className="form-label text-body-secondary fw-bold">Email Address</label>
              <input type="email" 
                     className="form-control" 
                     id="exampleFormControlInput1" 
                     value={formEmail}
                     onChange = {(e) => {
                                    setFormEmail(e.target.value);
                                  }} required/>
            </div>
            <div className="mb-3">
              <label for="name-input" className="form-label text-body-secondary fw-bold">Name</label>
              <input type="text" 
                     className="form-control" 
                     id="name-input" 
                     value={formName}
                     onChange = {(e) => {
                                  setFormName(e.target.value);
                                }} required/>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" className="form-label text-body-secondary fw-bold">Message</label>
              <textarea className="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="3" 
                        o
                        onChange = {(e) => {
                                      setFormMessage(e.target.value);
                                    }} required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
   
  );
};


const CREATE_MESSAGE_MUTATION = gql`
  mutation (
    $email: String!
    $name: String!
    $message: String!
  ) 
  {
    createFeedback(
      input: {
        email: $email
        name: $name
        message: $message
      }
    ) 
    {
      message {
        email
        name
        message}
      
    }
  }
`;

export default Feedback;