import React, { useEffect } from "react";
import { Router, Route, useLocation } from "react-router-dom";
import history from "./components/History";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
//import logo from './logo.svg';
import "./App.css";
import Home from "./components/Home";
import AllResources from "./components/AllResources";
import AddResource from "./components/AddResource";
import EditResource from "./components/EditResource";
import ResourceDetail from "./components/ResourceDetail";
import Profile from "./components/Profile";
import UpdateProfile from "./components/UpdateProfile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TreeCalendar from "./components/Calendar";
import Help from "./components/Help";
import Feedback from "./components/Feedback";
// import OccurrenceDetail from "./components/OccurrenceDetail";
// import EditOccurrence from "./components/EditOccurrence";
import StepCommunities from "./components/StepCommunities";
import StepTracks from "./components/StepTracks";
import StepCompetencies from "./components/StepCompetencies";
import StepRegion from "./components/StepRegion";
// import SelfPaced from "./components/SelfPaced";
import PendingApprovals from "./components/PendingApprovals";
import {transitions, positions, types, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

export const UserContext = React.createContext();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const options = {
  type: types.ERROR,
  timeout: 5000,
  position: positions.TOP_LEFT,
  transition: transitions.SCALE
  
};

const App = () => (
  
  <Query
    query={USER_QUERY}
    variables={{ groupNames: ["Resource Creator"] }}
    fetchPolicy="cache-and-network"
  >
    {({ data, loading, error }) => {
      if (loading) return "Loading...";
      if (error) return `Error! ${error.message}`;
      const currentUser = data;
      return (
        <Router history={history}>
          <UserContext.Provider value={currentUser}>
            <Provider template={AlertTemplate} {...options}>
              <ScrollToTop />
              <Header />
              <Nav currentUser={currentUser} />
              <Route exact path="/" component={Home} />
              <Route exact path="/allresources" component={AllResources} />
              <Route
                exact
                path="/pendingapprovals"
                component={PendingApprovals}
              />
              <Route exact path="/addresource" component={AddResource} />
              <Route exact path="/editresource/:id" component={EditResource} />
              <Route
                exact
                path="/resourcedetail/:id"
                component={ResourceDetail}
              />
              <Route exact path="/profile/:id" component={Profile} />
              <Route
                exact
                path="/updateprofile/:id"
                component={UpdateProfile}
              />
              <Route exact path="/calendar" component={TreeCalendar} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/communities" component={StepCommunities} />
              <Route exact path="/tracks" component={StepTracks} />
              <Route exact path="/competencies" component={StepCompetencies} />
              <Route exact path="/region" component={StepRegion} />
              <Footer />
            </Provider>
          </UserContext.Provider>
        </Router>
      );
    }}
  </Query>
);

export const USER_QUERY = gql`
  query($groupNames: [String]) {
    allResources {
      edges {
        node {
          id
          title
          description
          nextOccurrence
          recurrences
          rrule
          program {
            id
            title
          }
          formatType {
            title
            id
          }
          location
          nurseInterest
          hyperlink
          registrationLink
          virtualMeetingLink
          startDate
          endDate
          facultyLeader
          kl2exclusive
          traexclusive
          maexclusive
          icsToggle
          createdBy{
            username
          }
          approved
          tracks {
            name
            id
          }
          communities {
            name
            id
          }
          competencies {
            name
            id
          }
          region {
            name
          }
          event {
            id
          }
        }
      }
    }
    communities {
      id
      name
    }
    tracks {
      id
      name
    }
    competencies {
      id
      name
    }
    allFormats {
      id
      title
    }
    userHasPermission(groupNames: $groupNames)
    user {
      id
      isSuperuser
      username
      firstName
      lastName
      email
      kL2Scholar
      translationalResearchAcademy
      mentorAcademy
      subscriptionSet {
        id
        resource {
          rrule
          location
          nextOccurrence
          recurrences
          registrationLink
          virtualMeetingLink
          region {
            name
          }
          event {
            id
            title
            start
            end
            rule {
              frequency
            }
            occurrenceSet {
              originalStart
              start
              originalEnd
              end
              cancelled
            }
          }
          id
          title
          description
          kl2exclusive
          maexclusive
          traexclusive
          icsToggle
          startDate
          endDate
          formatType {
            title
            id
          }
          tracks {
            name
            id
          }
          communities {
            name
            id
          }
          competencies {
            name
            id
          }
        }
      }
      tracks {
        name
        id
      }
      communities {
        name
        id
      }
      competencies {
        name
        id
      }
    }
  }
`;

export default App;
