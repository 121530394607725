import React, { useContext } from "react";
import moment from "moment";
// import { useQuery } from "@apollo/react-hooks";
// import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import { UserContext } from "../App";
// import { EVENT_OCCURRENCES } from "./Home";
import { downloadICS } from "./ResourceDetail";

const UpcomingTrack = ({ resourceList }) => {
  const currentUser = useContext(UserContext).user;


  const filteredResourceList = resourceList.filter(
    //only return elements with a startDate greater than the current date and are not starred by the user
    (a) =>
      !(a.node.kl2exclusive && !currentUser.kL2Scholar) &&
      !(a.node.traexclusive && !currentUser.translationalResearchAcademy) &&
      !(a.node.maexclusive && !currentUser.mentorAcademy) &&
      ((a.node.recurrences === "" &&
        new Date(a.node.startDate).getTime() >= Date.now()) ||
        (a.node.recurrences !== "" &&
          new Date(a.node.nextOccurrence).getTime() >= new Date().getTime()))  &&
  
      currentUser.subscriptionSet.find(
        (element) => element.resource.id === a.node.id
      ) === undefined
  );


  return (
    <div className="card-body">
      {filteredResourceList
        .sort(
          (a, b) =>
            new Date(a.node.startDate).getTime() -
            new Date(b.node.startDate).getTime()
        )
        .slice(0, 3)
        .map((subscription) => (
          <div key={subscription.node.id}>
            {
              <div className="text-start">
                <div className="btn-group text-start">
                  <Subscribe resourceId={subscription.node.id} />
                  <div>
                    <Link
                      to={{
                        pathname: `resourcedetail/${subscription.node.id}`,
                        state: { resource: subscription.node },
                      }}
                    >
                      <div
                        className="btn-link ms-2 nav-link"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {subscription.node.title}{" "}
                      </div>
                    </Link>

                    <div className="d-flex align-items-start align-items-center">
                      {subscription.node.icsToggle === true && (
                        <button
                          type="button"
                          className="btn btn-sm d-inline-block"
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onClick={function () {
                            downloadICS(subscription.node);
                          }}
                        >
                          <i
                            className="fa fa-calendar"
                            title="Add To Calendar"
                          />
                        </button>
                      )}
                      {subscription.node.icsToggle === false && (
                        <a
                          href={subscription.node.registrationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <i className="fa fa-pencil" title="Register" />
                        </a>
                      )}
                      <div className="d-inline-block ms-2">
                        {subscription.node.recurrences !== ""
                          ? moment(subscription.node.nextOccurrence).format("MMMM D, YYYY")
                          : moment(subscription.node.startDate).format(
                              "MMMM D, YYYY"
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        ))}
      {filteredResourceList.length > 3 && (
        <Link
          to={`../allresources`}
          className="card-footer-item link-underline link-underline-opacity-0 "
          style={{ float: "right", padding: "0" }}
        >
          View More
        </Link>
      )}
    </div>
  );
};

export const UPCOMING_TRACK_RESOURCES = gql`
  query($search: [String]) {
    allResourcesTracks(search: $search) {
      edges {
        node {
          id
          title
          description
          rrule
          startDate
          endDate
          maexclusive
          kl2exclusive
          traexclusive
          region {
            name
          }
          formatType {
            title
            id
          }
          event {
            id
          }
          tracks {
            name
            id
          }
          communities {
            name
            id
          }
          competencies {
            name
            id
          }
        }
      }
    }
  }
`;

export default UpcomingTrack;

