import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useQuery } from "@apollo/react-hooks";
import { RESOURCES_QUERY } from "../Queries";
import { UserContext } from "../App";
// import { EVENT_OCCURRENCES } from "./Home";

const AllResources = () => {
  const currentUser = useContext(UserContext).user;
  const userHasPermission = useContext(UserContext).userHasPermission;
  const [filterFormat, setFilterFormat] = useState("all");
  const [filterProgram, setFilterProgram] = useState("all");
  const [filterTracks, setFilterTracks] = useState("all");
  const [filterCommunities, setFilterCommunities] = useState("all");
  const [filterCompetencies, setFilterCompetencies] = useState("all");
  const [filterKl2, setFilterKl2] = useState(false);
  const [filterTra, setFilterTra] = useState(false);
  const [filterMa, setFilterMa] = useState(false);
  const [filterTitle, setFilterTitle] = useState("");
  const [filterStart, setFilterStart] = useState("");
  const [filterEnd, setFilterEnd] = useState("");
  const [sortOption, setSortOption] = useState("Alphabetical");
  const { loading, error, data } = useQuery(RESOURCES_QUERY);
  const [archive, setArchive] = useState(false);
  const [filterCreated, setFilterCreated] = useState(false);

  // TODO: const getNextDate = (eventID) => (
  //   <Query query={EVENT_OCCURRENCES} variables={{ id: eventID }}>
  //     {({ data, loading, error }) => {
  //       if (loading)
  //         return (
  //           <div className="spinner-border" role="status">
  //             <span className="sr-only">Loading...</span>
  //           </div>
  //         );
  //       if (error) return <div></div>;
  //       if (currentUser.isSuperuser && data.singleEventOccurrences.length === 0)
  //         return (
  //           <div>
  //             <b>DATE ERROR</b>
  //           </div>
  //         );
  //       if (data.singleEventOccurrences.length === 0) return <div></div>;
  //       return new Date(data.singleEventOccurrences[0].start);
  //     }}
  //   </Query>
  // );
  const searchByTitle= (a) => {
    return (a.node.title
              .toLowerCase()
              .replace(/[^A-Za-z0-9]/g, "")
              .includes(filterTitle.toLowerCase().replace(/[^A-Za-z0-9]/g, "")) || filterTitle === "")
	};

  const searchByDate= (a) => {
    return  ((filterStart <= a.node.nextOccurrence && a.node.nextOccurrence <= filterEnd) ||
            (filterStart === "" && filterEnd === "")) 
  };

  const filterCheckboxes = (a) => {
    return  ((a.node.kl2exclusive && filterKl2) || filterKl2 === false) &&
            ((a.node.traexclusive && filterTra) || filterTra === false) &&
            ((a.node.maexclusive && filterMa) || filterMa === false) 
	};

  const checkExclusiveness = (a) => {
    return !(a.node.kl2exclusive && !currentUser.kL2Scholar) &&
           !(a.node.traexclusive && !currentUser.translationalResearchAcademy) &&
           !(a.node.maexclusive && !currentUser.mentorAcademy)
	};

  const filterByDropdowns = (a) => {
    return  (a.node.formatType.id === filterFormat || filterFormat === "all") &&
            (a.node.program.id === filterProgram || filterProgram === "all") &&
            (a.node.communities.some((el) => el.id === filterCommunities) || filterCommunities === "all") &&
            (a.node.tracks.some((el) => el.id === filterTracks) ||  filterTracks === "all") &&
            (a.node.competencies.some((el) => el.id === filterCompetencies) || filterCompetencies === "all")
  };

  const sortResources = (a, b) => {
    var tempa = ""
    var tempb = ""
    //sort resource list based on currently selected sorting option
    switch (sortOption) {
      case "Alphabetical":
        return a.node.title
          .replace(/[^A-Za-z0-9]/g, "")
          .localeCompare(b.node.title.replace(/[^A-Za-z0-9]/g, ""));
      case "Date":
        if (a.node.startDate == null) {
          tempa = new Date("January 1, 2030 00:00:00");
        } else {
          tempa = new Date(a.node.startDate);
        }
        if (b.node.startDate == null) {
          tempb = new Date("January 1, 2030 00:00:00");
        } else {
          tempb = new Date(b.node.startDate);
        }
        return tempa.getTime() - tempb.getTime();
      case "Program":
        return a.node.program.title.localeCompare(b.node.program.title);
      case "Format Type":
        return a.node.formatType.title.localeCompare(b.node.formatType.title);
      case "Archive":
        if (new Date(a.node.endDate).getTime() <= new Date().getTime()) {
          return a.node;
        } else {
          return;
        }
      default:
        return a.node.title.localeCompare(b.node.title);
    }
  };

  const checkArchive = (a) => {
    let returnVal = false;
    if (new Date(a.node.endDate).getTime() < new Date().getTime()) {
      if (
        a.node.rrule !== null &&
        a.node.rrule.length < 1 &&
        a.node.formatType.title !== "Self-paced"
      ) {
        returnVal = false;
      } else {
        returnVal = true;
      }
    } else {
      returnVal = true;
    }
    if (archive) {
      returnVal = !returnVal;
    }
    return returnVal;
  };

  const checkCreatedBy = (a) => {
    if (filterCreated){
      if (a.node.createdBy != null){
      if (currentUser.username == a.node.createdBy.username) {
        return true
      }
      else{
        return false
      }
    }
    else{
      return false
    }
    }
    else {
      return true
    }
  }

  const mapFormatType = (formatType) => {
    var newTitle = "";
    switch (formatType) {
      case "Self-paced":
        newTitle = "Self Paced";
        break;
      case "Instructor-led, online":
        newTitle = "Online";
        break;
      case "Instructor-led, hybrid":
        newTitle = "Hybrid";
        break;
      case "Instructor-led, classroom only":
        newTitle = "In Person";
        break;
      default:
        newTitle = "";
        break;
    }
    return newTitle;
  };

  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (error) return `Error Occurred! ${error.message}`;

  return (
    <div className="container">
      <div className="text-md-end text-center mt-3 mb-2">
        {userHasPermission && (
          <Link
            className="btn btn-secondary btn-sm link-underline link-underline-opacity-0"
            style={{ fontSize: "large" }}
            target="_blank"
            to={"/addresource"}
          >
            Add New Resource
          </Link>
        )}
      </div>
      <div className="text-end mt-3 mb-2">
        {/* TODO:  <button
          className="btn btn-secondary"
          type="button"
          data-toggle="collapse"
          data-target=".multi-collapse"
          aria-expanded="false"
          aria-controls="filter1 filter2 filter3 filter4 filter5 filter6"
        >
          Filter Results
        </button> */}
      </div>
      <div className="row p-2">
        <div className="col" role="group">
          <label>Sorting Options:</label>
          <button
            className="btn btn-secondary btn-sdm m-2"
            type="button"
            data-bs-toggle="button"
            onClick={() => setSortOption("Alphabetical")}
          >
            Alphabetical
          </button>
          <button
            className="btn btn-secondary btn-sdm m-2"
            type="button"
            data-bs-toggle="button"
            onClick={() => setSortOption("Date")}
          >
            Start Time
          </button>
          <button
            className="btn btn-secondary btn-sdm m-2"
            type="button"
            data-bs-toggle="button"
            onClick={() => setSortOption("Program")}
          >
            Program
          </button>
          <button
            className="btn btn-secondary btn-sdm m-2"
            type="button"
            data-bs-toggle="button"
            onClick={() => setSortOption("Format Type")}
          >
            Format Type
          </button>
          {(currentUser.isSuperuser || userHasPermission) && (
            <button
              className="btn btn-secondary btn-sdm m-2"
              type="button"
              data-bs-toggle="button"
              onClick={() => setArchive(!archive)}
            >
              Archive
            </button>
          )}
          {userHasPermission && (
            <button
              className="btn btn-secondary btn-sdm m-2"
              type="button"
              data-bs-toggle="button"
              onClick={() => setFilterCreated(!filterCreated)}
            >
              Created By Me
            </button>
          )}
        </div>
      </div>
      <div className="row p-2">
        <div className="col">
          <label>Start Time</label>
          <input
            className="input"
            id="startDate"
            type="date"
            onChange={(e) => {
              setFilterStart(e.target.value);
            }}
          ></input>
        </div>
        <div className="col">
          <label>End Time</label>
          <input
            className="input"
            id="endDate"
            type="date"
            onChange={(e) => setFilterEnd(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="row p-2">
        <div className="col col-sm col-md">
          <div className="" id="filter1">
            <select
              className="form-control"
              id="formatSelector"
              onChange={(event) => {
                setFilterFormat(event.target.value);
              }}
            >
              <option value="all">Format Type</option>
              {data.allFormats.map((format) => {
                return (
                  <option key={format.id} value={format.id}>
                    {format.title}
                  </option>
                );
              })}
              {/* TODO: <option value="Self-paced">Self-paced</option>
              <option value="Instructor-led, online only">
                Instructor-led, online only
              </option>
              <option value="Instructor-led, hybrid">
                Instructor-led, hybrid
              </option>
              <option value="Instructor-led, classroom only">
                Instructor-led, classroom only
              </option> */}
            </select>
          </div>
        </div>
        <div className="col col-sm col-md">
          <div>
            <select
              className="form-control"
              id="formatSelector"
              onChange={(event) => {
                setFilterProgram(event.target.value);
              }}
            >
              <option value="all">Program</option>
              {data.allPrograms.map((program) => {
                return (
                  <option key={program.id} value={program.id}>
                    {program.title}
                  </option>
                );
              })}
              {/* TODO: <option value="Community 1">Community 1</option>
              <option value="Community 2">Community 2</option>
              <option value="Community 3">Community 3</option> */}
            </select>
          </div>
        </div> 
        <div className="col col-sm col-md">
          <div>
            <select
              className="form-control"
              id="formatSelector"
              onChange={(event) => {
                setFilterTracks(event.target.value);
              }}
            >
              <option value="all">Tracks</option>
              {data.tracks.map((track) => {
                return (
                  <option key={track.id} value={track.id}>
                    {track.name}
                  </option>
                );
              })}
              {/* TODO: <option value="Community 1">Community 1</option>
              <option value="Community 2">Community 2</option>
              <option value="Community 3">Community 3</option> */}
            </select>
          </div>
        </div> 
        <div className="col col-sm col-md">
          <div>
            <select
              className="form-control"
              id="formatSelector"
              onChange={(event) => {
                setFilterCommunities(event.target.value);
              }}
            >
              <option value="all">Communities</option>
              {data.communities.map((community) => {
                return (
                  <option key={community.id} value={community.id}>
                    {community.name}
                  </option>
                );
              })}
              {/* TODO: <option value="Community 1">Community 1</option>
              <option value="Community 2">Community 2</option>
              <option value="Community 3">Community 3</option> */}
            </select>
          </div>
        </div>
        <div className="col-3 col-sm-3 col-md-3">
          <div>
            <select
              className="form-control"
              id="formatSelector"
              onChange={(event) => {
                setFilterCompetencies(event.target.value);
              }}
            >
              <option value="all">Competencies</option>
              {data.competencies.map((competency) => {
                return (
                  <option key={competency.id} value={competency.id}>
                    {competency.name}
                  </option>
                );
              })}
              {/* TODO: <option value="Community 1">Community 1</option>
              <option value="Community 2">Community 2</option>
              <option value="Community 3">Community 3</option> */}
            </select>
          </div>
        </div>
      </div>
      <div className="row p-2 ms-1">
        {currentUser.kL2Scholar && (
          <div className="col-sm-3 ms-md-2 ms-0">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                onClick={(event) => {
                  setFilterKl2(event.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                KL2 Scholars
              </label>
            </div>
          </div>
        )}
        {currentUser.translationalResearchAcademy && (
          <div className="col-sm-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck2"
                onClick={(event) => {
                  setFilterTra(event.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="defaultCheck2">
                Translational Research Academy
              </label>
            </div>
          </div>
        )}
        {currentUser.mentorAcademy && (
          <div className="col-sm-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck3"
                onClick={(event) => {
                  setFilterMa(event.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="defaultCheck3">
                Mentor Academy
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="row p-2">
        <div className="col">
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Title"
              onInput={(event) => {
                setFilterTitle(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div>
        {data.allResources.edges
          .sort((a, b) => sortResources(a, b))
          .filter(
            (a) => 
              filterByDropdowns(a) &&
              checkArchive(a) &&
              searchByTitle(a) &&
              searchByDate(a) &&
              filterCheckboxes(a) &&
              checkExclusiveness(a) &&
              checkCreatedBy(a) && 
              a.node.approved === true
          )
          .map((resource) => (
            <div key={resource.node.id}>
              <div className="row">
                <Link
                  to={{
                    pathname: `resourcedetail/${resource.node.id}`,
                    state: {
                      resource: resource.node,
                    },
                  }}
                >
                  <h4 className="btn-link" style={{ color: "black" }}>
                    {resource.node.title}

                    <span className="ms-3 badge rounded-pill text-bg-secondary">
                      {mapFormatType(resource.node.formatType.title)}
                    </span>
                  </h4>
                </Link>
              </div>
              {ReactHtmlParser(resource.node.description)}
              <div className="col-sm-11  mt-2">
                {resource.node.recurrences !== "" ? (
                  <div>
                    {moment(resource.node.nextOccurrence).format(
                      "MMMM D, YYYY"
                    )}
                  </div>
                ) : (
                  ""
                )}
                {resource.node.startDate != null &&
                resource.node.recurrences === ""
                  ? `${moment(resource.node.startDate).format("MMMM D, YYYY")}`
                  : ""}
              </div>
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
};

export const FEED_QUERY = gql`
  query Feed($first: Int) {
    relayResources(first: $first) {
      edges {
        node {
          id
          title
          description
          rrule
          program {
            id
            title
          }
          formatType {
            title
            id
          }
          location
          nurseInterest
          hyperlink
          registrationLink
          virtualMeetingLink
          startDate
          endDate
          facultyLeader
          nextOccurrence
          recurrences
          kl2exclusive
          traexclusive
          maexclusive
          approved
          createdOn
          icsToggle
          tracks {
            name
            id
          }
          communities {
            name
            id
          }
          competencies {
            name
            id
          }
          region {
            name
          }
          event {
            id
          }
        }
      }
    }

    communities {
      id
      name
    }
    tracks {
      id
      name
    }
    competencies {
      id
      name
    }
    allFormats {
      id
      title
    }
    allPrograms {
      id
      title
    }
  }
`;

export default AllResources;
