import React, { useContext } from "react";
import { UserContext } from "../App";
// import moment from "moment";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
// import { Query } from "react-apollo";
// import { EVENT_OCCURRENCES } from "./Home";

const SelfPaced = ({resourceList}) => {
  const currentUser = useContext(UserContext).user;

  // TODO:  const getNextDate = (eventID) => (
  //     <Query query={EVENT_OCCURRENCES} variables={{ id: eventID }}>
  //       {({ data, loading, error }) => {
  //         if (loading)
  //           return (
  //             <div className="spinner-border" role="status">
  //               <span className="sr-only">Loading...</span>
  //             </div>
  //           );
  //         if (error) return `Error! ${error.message}`;
  //         return (
  //           <div>
  //             {moment(new Date(data.singleEventOccurrences[0].start)).format(
  //               "MMMM DD, YYYY"
  //             )}
  //           </div>
  //         );
  //         // return <div></div>setNext(data.singleEventOccurrences[0].start);
  //       }}
  //     </Query>
  //   );

  return (
    <div className="card-body">
      {resourceList
        .filter(
          (a) =>
            a.node.formatType.title === "Self-paced" &&
            !(a.node.kl2exclusive && !currentUser.kL2Scholar) &&
            !(
              a.node.traexclusive && !currentUser.translationalResearchAcademy
            ) &&
            !(a.node.maexclusive && !currentUser.mentorAcademy) &&
            currentUser.subscriptionSet.find(
              (element) => element.resource.id === a.node.id
            ) === undefined
        )
        .slice(0, 3)
        .map((resource) => (
          <div key={resource.node.id}>
            {
              <div className="text-start">
                <div className="btn-group text-start">
                  <Subscribe resourceId={resource.node.id} />
                  <div className="d-flex align-items-center">
                    <Link
                      to={{
                        pathname: `resourcedetail/${resource.node.id}`,
                        state: { resource: resource.node },
                      }}
                    >
                      <div
                        className="btn-link m-2 nav-link"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {resource.node.title}{" "}
                      </div>
                    </Link>

                    <div>
                      {/* TODO: {resource.node.rrule != ""
                        ? getNextDate(resource.node.event.id)
                        : moment(resource.node.startDate).format(
                            "MMMM DD, YYYY"
                          )} */}
                      {/* TODO: <button
                      type="button"
                      className="btn btn-sm"
                      style={{
                        backgroundColor: "transparent",
                      }}
                      onClick={function () {
                        downloadICS(subscription.node);
                      }}
                    >
                      <i className="fa fa-calendar" />
                    </button> */}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        ))}
        {resourceList.length > 3 && (
      <Link
        to={`../allresources`}
        className="card-footer-item link-underline link-underline-opacity-0 "
        style={{ float: "right", padding: "0" }}
      >
        View More
      </Link>
      )}
    </div>
  );
};

export default SelfPaced;
