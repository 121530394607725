import React from "react";

// const linkToPPT =
//   "https://wakehealth.sharepoint.com/:p:/r/teams/Operations/_layouts/15/doc2.aspx?sourcedoc=%7B46153801-3E91-4864-A08F-D7DEFB9F424A%7D&file=TREE%20Profile%20Build%20SOP%20-%20TR%20edits.pptx&action=edit&mobileredirect=true&DefaultItemOpen=1&ct=1642698216025&wdOrigin=OFFICECOM-WEB.MAIN.OTHER&cid=469327a6-b409-436e-b526-37c28ddb9f5a";

const Help = () => {
  return (
    <div className="h-100 row align-items-center">
      <div className="col">
        <iframe
        title="ppt"
          src="https://wakehealth.sharepoint.com/teams/Operations/_layouts/15/Doc.aspx?sourcedoc={46153801-3e91-4864-a08f-d7defb9f424a}&amp;action=embedview&amp;wdAr=1.7777777777777777"
          width="50%"
          height="600px"
          frameBorder="0"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          This is an embedded{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://office.com">
            Microsoft Office
          </a>{" "}
          presentation, powered by{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://office.com/webapps">
            Office
          </a>
          .
        </iframe>
      </div>
    </div>
  );
};

export default Help;
