import React from "react";
import { ApolloConsumer } from "react-apollo";
import { Link } from "react-router-dom";

const Logoff = () => {
  const handleLogoff = client => {
    localStorage.removeItem("authToken");
    client.writeData({ data: { isLoggedIn: false } });
  };

  return (
    <ApolloConsumer>
      {client => (
        <Link
          to={`/`}
          className="btn btn-link text-dark align-self-center"
          style={{
            borderLeft: "2px solid #ebebeb",
            borderRadius: "1px",
            backgroundColor: "#f5f5f5",
            width: "95px",
            height: "95px",
            transition: "background-color 0.5s ease"
          }}
        >
          <div>
            <i className="fa fa-power-off" />
          </div>
          <button
            className="btn btn-link"
            style={{ color: "black" }}
            onClick={() => handleLogoff(client)}
          >
            Logoff
          </button>
        </Link>
      )}
    </ApolloConsumer>
  );
};

export default Logoff;
