import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
//import logo from './logo.svg';
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Logoff from "./components/Logoff";

// class Auth extends Component {
//   render() {
//     return (
//       <React.Fragment>
//         <Router>
//           <Header />
//           <div>
//             <Route exact path="/" component={Login} />
//             <Route exact path="/signup" component={Signup} />
//             <Route exact path="/logoff" component={Logoff} />
//           </div>
//         </Router>
//         <Footer />
//       </React.Fragment>
//     );
//   }
// }

const Auth = () => {
  window.location.assign(process.env.REACT_APP_OAUTH_REDIRECT);
  return <div></div>;
};
export default Auth;
