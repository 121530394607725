import React, { useContext } from "react";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import { default as treelogo } from "./img/round_tree_logo.svg";
// import { USER_QUERY } from "../App";
// import { useQuery } from "@apollo/react-hooks";

const Nav = () => {
  const currentUser = useContext(UserContext).user;
  // const { loading, error, data } = useQuery(USER_QUERY);
  return (

      <nav style={{backgroundColor: "#9D7C2F"}} className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
        <Link
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "

                  style={{ fontSize: "large" }}
                  to={"/"}
                 
                >
                  <i className="fa fa-home me-2"/>
                  Home
                </Link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav nav-fill w-100">
              <li className="nav-item">
              <Link
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                  style={{ fontSize: "large" }}
                  to={"/allresources"}
                >
                  <i className="fa fa-book me-2"/>
                  Resources
                </Link>
              </li>
              <li className="nav-item">
              <Link
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                  style={{ fontSize: "large" }}
                  to={"/calendar"}
                >
                  <i className="fa fa-calendar me-2"/>
                  Calendar
                </Link>
              </li>
              <li className="nav-item">
              <Link
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                  style={{ fontSize: "large" }}
                  to={`/profile/${currentUser.id}`}
                >
                  <i className="fa fa-user me-2"/>
                  Profile
                </Link>
              </li>
              <li className="nav-item">
              <Link
                  className="nav-link btn btn-link text-light"
                  style={{ fontSize: "large" }}
                  to={"/help"}
                >
                  <i className="fa fa-question-circle me-2"/>
                  Help
                </Link>
              </li>
              <li className="nav-item">
              <Link
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                  style={{ fontSize: "large" }}
                  to={"/feedback"}
                >
                  <i className="fa fa-comment me-2"/>
                  Feedback
                </Link>
              </li>
              {currentUser.isSuperuser === true && (
                <li className="nav-item">
                  <Link
                    className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                    style={{ fontSize: "large" }}
                    to={`/admin/`}
                    target="_blank"
                  >
                    <i className="fa fa-lock" style={{ paddingRight: "8px" }} />
                    Admin
                  </Link>
                </li>
              )}
               {currentUser.isSuperuser === true && (
                <li className="nav-item">
                  <Link
                    className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                    style={{ fontSize: "large" }}
                    to={`/pendingapprovals`}
                  >
                    <i
                      className="fa fa-thumbs-up"
                      style={{ paddingRight: "8px" }}
                    />
                    Approvals
                  </Link>
                </li>
              )}
              <li className="nav-item">
                 <a
                  href={`${process.env.REACT_APP_BASE}/oauth2/logout`}
                  className="nav-link btn btn-link text-light link-underline link-underline-opacity-0 "
                  style={{
                    fontSize: "large",
                  }}
                >
                  <i
                    className="fa fa-power-off"
                    style={{ paddingRight: "10px" }}
                  />
                  Logout
                </a>
              </li>
              {/* <li className="nav-item d-none d-lg-block">
              <img
              className="img-fluid"
              alt="tree_logo"
              width="25%"
              height="25%"
              src={treelogo}
            />
              </li> */}
            </ul>
            <div className="navbar-header d-none d-lg-block ms-5">
      <a className="navbar-brand" href="/">
        <img alt="Brand" height="25%" width="25%" src={treelogo} />
      </a>
    </div>
          </div>
          
        </div>
      </nav>

    // <div style={{ backgroundColor: "#9D7C2F" }}>
    //   <div className="container">
    //   <nav
    //     style={{
    //       backgroundColor: "#9D7C2F",
    //     }}
    //     className="navbar navbar-dark  navbar-expand-lg"
    //   >
        
    //       <div className="row">
    //         <div className="col">
    //       <button
    //         className="navbar-toggler"
    //         type="button"
    //         data-toggle="collapse"
    //         data-target="#navbarSupportedContent"
    //         aria-controls="navbarSupportedContent"
    //         aria-expanded="false"
    //         aria-label="Toggle navigation"
    //       >
    //         <span className="navbar-toggler-icon" />
    //       </button>
    //       <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //         <ul className="navbar-nav w-100 nav-fill">
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link btn btn-link text-light "
    //               style={{ fontSize: "large" }}
    //               to={"/"}
    //             >
    //               <i className="fa fa-home" style={{ paddingRight: "10px" }} />
    //               Home
    //             </Link>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link btn btn-link text-light"
    //               style={{ fontSize: "large" }}
    //               to={"/allresources"}
    //             >
    //               <i className="fa fa-book" style={{ paddingRight: "10px" }} />
    //               Resources
    //             </Link>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link btn btn-link text-light"
    //               style={{ fontSize: "large" }}
    //               to={"/calendar"}
    //             >
    //               <i
    //                 className="fa fa-calendar"
    //                 style={{ paddingRight: "10px" }}
    //               />
    //               Calendar
    //             </Link>
    //           </li>
    //           {currentUser && (
    //             /* {data.user != null && ( */
    //             <li className="nav-item">
    //               <Link
    //                 className="nav-link btn btn-link text-light"
    //                 style={{ fontSize: "large" }}
    //                 // to={`/profile/${data.user.id}`}
    //                 to={`/profile/${currentUser.id}`}
    //               >
    //                 <i
    //                   className="fa fa-user"
    //                   style={{ paddingRight: "10px" }}
    //                 />
    //                 Profile
    //               </Link>
    //             </li>
    //           )}

    //           <li className="nav-item">
    //             <Link
    //               className="nav-link btn btn-link text-light"
    //               style={{ fontSize: "large" }}
    //               to={"/help"}
    //             >
    //               <i
    //                 className="fa fa-question-circle"
    //                 style={{ paddingRight: "8px" }}
    //               />
    //               Help
    //             </Link>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               to={"#"}
    //               className="nav-link btn btn-link text-light"
    //               style={{ fontSize: "large", fontWeight: "bold" }}
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 window.open(
    //                   `mailto:learningtree@wakehealth.edu?subject=Learning TREE Feedback`
    //                 );
    //               }}
    //             >
    //               <i
    //                 className="fa fa-comment"
    //                 style={{ paddingRight: "8px" }}
    //               />
    //               Feedback
    //             </Link>
    //           </li>
    //           {currentUser.isSuperuser === true && (
    //             <li className="nav-item">
    //               <Link
    //                 className="nav-link btn btn-link text-light"
    //                 style={{ fontSize: "large" }}
    //                 to={`/admin/`}
    //                 target="_blank"
    //               >
    //                 <i className="fa fa-lock" style={{ paddingRight: "8px" }} />
    //                 Admin
    //               </Link>
    //             </li>
    //           )}
    //           {currentUser.isSuperuser === true && (
    //             <li className="nav-item">
    //               <Link
    //                 className="nav-link btn btn-link text-light"
    //                 style={{ fontSize: "large" }}
    //                 to={`/pendingapprovals`}
    //               >
    //                 <i
    //                   className="fa fa-thumbs-up"
    //                   style={{ paddingRight: "8px" }}
    //                 />
    //                 Approvals
    //               </Link>
    //             </li>
    //           )}
    //           <li className="nav-item">
    //             <a
    //               href={`${process.env.REACT_APP_BASE}/oauth2/logout`}
    //               className="nav-link btn btn-link text-light"
    //               style={{
    //                 fontSize: "large",
    //               }}
    //             >
    //               <i
    //                 className="fa fa-power-off"
    //                 style={{ paddingRight: "10px" }}
    //               />
    //               Logout
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //       </div>
    //       <div className="col float-left">
    //       <div className="mx-auto my-2 order-0 order-md-1 position-relative float-right">
    //         <img
    //           className="mx-auto float-right"
    //           alt="tree_logo"
    //           width="25%"
    //           height="25%"
    //           src={treelogo}
    //         />
    //         </div>
    //         </div>
    //         {/* <a class="mx-auto float-right" href="#">
    //           <img
    //             src={treelogo}
    //             class="rounded-circle float-right"
    //             style={{ height: "15%", width: "15%" }}
    //           />
    //         </a> */}
    //       </div>
    //     {/* </div> */}
    //   </nav>
    //   </div>
    // </div>
  );
};

export default Nav;
