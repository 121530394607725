import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UserContext, USER_QUERY } from "../App";
import history from "./History";
import {RRule} from "rrule";
import ReactHtmlParser from "react-html-parser";
import "react-quill/dist/quill.bubble.css";
import {
  RESOURCE_QUERY,
  RESOURCES_QUERY,
  DELETE_RESOURCE_QUERY,
} from "../Queries";
import { UPCOMING_TRACK_RESOURCES } from "./UpcomingTrack";
import { useAlert } from "react-alert";

const ResourceDetail = ({ match }) => {
  const { loading, error, data } = useQuery(RESOURCE_QUERY, {
    variables: {
      id: match.params.id,
    },
  });
  const currentUser = useContext(UserContext).user;
  const alert = useAlert();

  const [deleteResource] = useMutation(DELETE_RESOURCE_QUERY, {
    refetchQueries: [
      { query: RESOURCES_QUERY },
      { query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } },
      {
        query: UPCOMING_TRACK_RESOURCES,
        variables: { search: currentUser.tracks.map((track) => track.id) },
      },
    ],
  });
  const resource = data;

  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  


  if (error){
    return `Error! ${error.message}`;}

  return (
    <div className="container-fluid container-md m-t-20">
          <div className="row mb-3 mt-3 text-center gx-2 justify-content-center">
        <div className="col-12 col-xl-8 ">
          <div className="card mb-4 box-shadow">
            <div className="card-header">
              <h4 className="my-0 fw-normal">
                {resource.resource.title}
              </h4>
            </div>
            <div className="card-body" style={{ backgroundColor: "#f5f5f5" }}>
              <div className="row g-0">
                <div className="col p-3 text-start">
                  <div className="">
                    {/* <h4>Description </h4> */}
                   
                    {ReactHtmlParser(resource.resource.description)}
                  </div>
                </div>
              </div>

              <div className="row">
                {((currentUser.isSuperuser) || (currentUser.username===resource.resource.createdBy.username)) && (
                  <Link
                    to={`../editresource/${resource.resource.id}`}
                    className="col btn btn-outline-success"
                  >
                    Edit
                  </Link>
                )}
                {currentUser.isSuperuser && (
                  <button
                    className="col btn btn-outline-danger"
                    onClick={
                      async (e) => {
                        try {
                          e.preventDefault();
                          if (window.confirm("Delete?")) {
                            await deleteResource({ variables: { id: resource.resource.id },});
                            alert.show('Resource successfully deleted!', {timeout: 5000, position: 'top center', type: 'success'});
                            history.goBack();
                          }
                        }
                        catch (err) {
                          alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
                        }
                      }
                    }
                  >
                    Delete
                  </button>
                  
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 ">
          <div className="card" >
            <div className="container-fluid">
              <ul className="list-group list-group-flush small">

                {resource.resource.location !== "" &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 ps-0 text-start my-auto d-flex">
                        <i class="fa-solid fa-map-location-dot py-1"></i><span className="ps-2"><strong>Location:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8 my-auto ">
                        {resource.resource.location}
                      </div>
                    </div>
                  </li>
                }

                {resource.resource.program != null &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 ps-0 text-start my-auto d-flex">
                        <i class="fa fa-university py-1"></i><span className="ps-2"> <strong>Program:</strong></span>
                      </div>
                      <div className=" col-8 col-sm-8 col-md-8 my-auto">
                        {resource.resource.program.title}
                      </div>
                    </div>
                  </li>
                }

                {resource.resource.speaker !== "" &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 text-start ps-0 my-auto  d-flex">
                      <i class="fa-solid fa-person-chalkboard py-1"></i><span className="ps-2"><strong>Speaker:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8 my-auto">
                        {resource.resource.speaker}
                      </div>
                    </div>
                  </li>
                }
                
                <li className="list-group-item py-2">
                  <div className="row d-flex flex-nowrap">
                    <div className="col-4 col-sm-4 col-md-4 ps-0 text-start my-auto  d-flex">
                      <i class="fa-solid fa-chalkboard-user py-1"></i><span className="ps-2"><strong>Format:</strong></span>
                    </div>
                    <div className="col-8 col-sm-8 col-md-8 my-auto">
                      {resource.resource.formatType.title}
                    </div>
                  </div>
                </li>
                      
                {resource.resource.region.length !== 0 &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap ">
                      <div className="col-4  col-sm-4 col-md-4 ps-0 text-start my-auto  d-flex">
                        <i class="fa fa-globe py-1" aria-hidden="true"></i><span className="ps-2"><strong>Regions:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8  my-auto">
                        {resource.resource.region.map((region) => (
                        <div className="list-group" key={region.id}>
                          {region.name != null ? region.name : ""}
                        </div>
                        ))}
                      </div> 
                    </div>
                  </li>
                }

                {resource.resource.startDate !== null &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 ps-0 text-start my-auto  d-flex"> 
                        <i class="fa fa-calendar py-1" aria-hidden="true"></i><span className="ps-2"> <strong>Next Date:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8 my-auto">{resource.resource.recurrences !== ""
                        ? moment(resource.resource.nextOccurrence).format(
                            "MMMM D, YYYY - h:mm A"
                          )
                        : ""}
                        {resource.resource.startDate !== null &&
                        resource.resource.recurrences === ""
                          ? moment(
                              resource.resource.startDate +
                                resource.resource.startTime,
                              "YYYY-MM-DDLT"
                            ).format("MMMM D, YYYY - h:mm A") + "" : ""}
                      </div>
                    </div>
                  </li>
                }

                {resource.resource.recurrences !== "" && 
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-md-4 ps-0 text-start my-auto  d-flex">
                        <i class="fa-solid fa-repeat py-1"></i><span className="ps-2"><strong>Recurrence:</strong></span>
                      </div>
                      <div className="col-8 col-md-8  my-auto">
                        {resource.resource.recurrences !== ""
                          ? `Occurs ` +
                            RRule.fromString(resource.resource.recurrences).toText()
                          : ""}
                      </div>
                    </div>     
                  </li>
                }

                {resource.resource.contactName !== "" &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 text-start ps-0 my-auto  d-flex">
                      <i class="fa-solid fa-address-card py-1"></i><span className="ps-2"><strong>Contact:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8 my-auto">
                        {resource.resource.contactName}
                      </div>
                    </div>
                  </li>
                }
                
                {resource.resource.contactEmail !== "" &&
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 text-start ps-0 my-auto  d-flex">
                      <i class="fa-solid fa-envelope py-1"></i><span className="ps-2"><strong>Contact Email:</strong></span>
                      </div>
                      <div className="col-8 col-sm-8 col-md-8 my-auto">
                        {resource.resource.contactEmail}
                      </div>
                    </div>
                  </li>
                }

                {currentUser.isSuperuser && resource.resource.createdBy && (
                  <li className="list-group-item py-2">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-4 col-md-4 ps-0 text-start my-auto  d-flex">
                        <i class="fa-solid fa-screwdriver-wrench py-1"></i><span className="ps-2"> <strong>Created by:</strong></span>
                      </div>
                      <div className="col-8 col-md-8  my-auto">
                        {resource.resource.createdBy.firstName}{" "}
                        {resource.resource.createdBy.lastName}
                      </div>
                    </div>      
                  </li>
                )}

                {resource.resource.hyperlink !== "" &&
                  <li className="list-group-item py-2">
                    <a
                      style={{ color: "blue" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={resource.resource.hyperlink}
                    > 
                    <strong>Event Info Link</strong>
                    </a>
                  </li>
                }

                {resource.resource.registrationLink !== "" &&
                  <li className="list-group-item py-2">
                    <a
                      style={{ color: "blue" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={resource.resource.registrationLink}
                    >
                      <strong>Registration Link</strong>
                    </a>
                  </li>
                }

                {resource.resource.virtualMeetingLink !== "" &&
                  <li className="list-group-item py-2">
                    <a
                      style={{ color: "blue" }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={resource.resource.virtualMeetingLink}
                    >
                      <strong>Virtual Meeting Link</strong>
                    </a>
                  </li>
                }
                
                <li className=" list-group-item py-1">
                  <button
                      type="button"
                      href="#"
                      className="btn btn-primary btn-sm btn-block col-6"
                      onClick={(e) => {
                        e.preventDefault();
                        let body =
                          resource.resource.title +
                          " - " +
                          resource.resource.formatType.title +
                          "%0D%0A%0D%0A" +
                          resource.resource.description +
                          "%0D%0A%0D%0A" +
                          window.location.href;
                        window.open(
                          `mailto:?subject=Check out this course on Learning TREE&body=${body}`
                        );
                      }}
                    >
                      Share With Others
                  </button>
                </li>

                
                {(resource.resource.formatType.title ===
                "Instructor-led, hybrid" ||
                resource.resource.formatType.title ===
                  "Instructor-led, online" ||
                resource.resource.formatType.title ===
                  "Instructor-led, classroom only") &&
                resource.resource.icsToggle === true && (
                  <li className=" list-group-item py-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm btn-block col-6"
                      onClick={function () {
                        downloadICS(resource.resource);
                      }}
                    >
                      Add To Calendar
                    </button>
                  </li>    
                )}
                 
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const downloadICS = (resource) => {
  const ics = require("ics");
  // const fs = require("browserify-fs");
  const FileSaver = require("file-saver");
  let location = "";
  if (resource.location !== null) {
    location = resource.location;
  }
  let start = moment(resource.startDate + resource.startTime, "YYYY-MM-DDLT")
    .toDate();
  let end = moment(resource.endDate + resource.endTime, "YYYY-MM-DDLT")
    .toDate();

  let event = {
    title: resource.title,
    start: [
      start.getFullYear(),
      start.getMonth() + 1,
      start.getDate(),
      start.getHours(),
      start.getMinutes(),
    ],
    startInputType: "local",
    startOutputType: "local",
    location: location,
    end: [
      end.getFullYear(),
      end.getMonth() + 1,
      end.getDate(),
      end.getHours(),
      end.getMinutes(),
    ],
    description: resource.description.replace(/<[^>]+>/g, '') + ("\n") + resource.hyperlink,
    url: resource.hyperlink,
    recurrenceRule: resource.rrule.slice(6),
  };

  ics.createEvent(event, (error, value) => {
    if (error) {
      console.log(error);
      return;
    }
    var blob = new Blob([value], { type: "ics" });
    FileSaver.saveAs(blob, resource.title + ".ics");
  });
};


export default ResourceDetail;
