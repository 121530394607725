import React, { useState, useContext } from "react";
import { Mutation, Query } from "react-apollo";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import history from "./History";
// import Multiselect from "react-widgets/lib/Multiselect";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
// import { ExpandMore } from "@material-ui/icons";
import { UserContext } from "../App";
import { useAlert } from "react-alert";

const StepCommunities = () => {
  const [communities] = useState([]);

  const currentUser = useContext(UserContext).user;
  const alert = useAlert();
  
  const communitiesMulti = () => (
    <Query query={TRACKS_QUERY}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return data.communities.map((track) => {
          return (
            <Accordion
              expanded={false}
              key={track.id}
            >
              <AccordionSummary>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      id="checkboxCommunity"
                      style={{ color: "#5cb85c" }}
                      defaultChecked={data.user.communities.some(
                        (el) => el.id === track.id
                      )}
                      value={track.id}
                      color={"secondary"}
                    />
                  }
                  label={track.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{track.description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        });
      }}
    </Query>
  );

  const { loading, error, data } = useQuery(STEP_USER_QUERY);
  if (loading) return 0;
  if (error) return 0;

  const handleSubmit = async (event, updateUserCommunities) => {
    event.preventDefault();
    var counterCommunity = 0;
    var checks = document.getElementsByTagName("input");
    try {
      for (let i = 0; i < checks.length; i++) {
        if (checks[i].id === "checkboxCommunity") {
          if (checks[i].checked) {
            communities.push(checks[i].value);
            counterCommunity++;
          }
        }
      }
      if (counterCommunity === 0) {
        alert.show("Please select at least one Learning Community", {timeout: 5000, position: 'top center', type: 'info'});
        return;
      }
      await updateUserCommunities({
        variables: {
          communities:
            communities !== undefined
              ? communities
              : data.me.communities.map((track) => track.id),
        },
      });
      history.push("/tracks");
    }
    catch (err) {
      alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PROFILE_MUTATION_COMMUNITIES}
      refetchQueries={() => [{ query: TRACKS_QUERY }]}
    >
      {(updateUserCommunities, { loading, error }) => {
        if (loading)
          return (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          );
        if (error) return `Error! ${error.message}`;
        return (
          <div className="container">
            {/* <div className="title mt-2">Profile Setup: Step One</div> */}
            <div className="mt-3">
              Welcome, {currentUser.firstName}, to the Translational Research
              Educational Enhancement Tool (aka TREE), the one-stop,
              customizable portal to find, access, and manage educational
              offerings across the Wake Forest University School of Medicine!
            </div>
            <div className="mt-4">
              The heartbeat of TREE is the process of creating your TREE profile
              so that we can learn more about your career interests and
              competencies. Let's start by determining your Learning Community!
            </div>
            <div className="mt-4">
              <div className="text-success" style={{ display: "inline-block" }}>
                <b>Learning Communities</b>
              </div>{" "}
              <b style={{ display: "inline-block" }}>
                <u> Please select at least ONE </u>
              </b>
            </div>
            <form
              onSubmit={(event) => handleSubmit(event, updateUserCommunities)}
            >
              <div className="mt-5">{communitiesMulti()}</div>
              <div className="mt-5 text-end">
                <button className="button is-link">Next Step</button>
              </div>
            </form>
          </div>
        );
      }}
    </Mutation>
  );
};

export const TRACKS_QUERY = gql`
  {
    tracks {
      name
      id
      description
    }
    communities {
      name
      description
      id
    }
    competencies {
      name
      description
      id
    }
    user {
      id
      tracks {
        id
        name
      }
      communities {
        id
        name
      }
      competencies {
        id
        name
      }
    }
  }
`;

export const STEP_USER_QUERY = gql`
  {
    user {
      username
      id
      communities {
        id
        name
      }
    }
  }
`;

const UPDATE_PROFILE_MUTATION_COMMUNITIES = gql`
  mutation($communities: [String]) {
    updateUserCommunities(input: { communities: $communities }) {
      user {
        id
        username
      }
    }
  }
`;

export default StepCommunities;
