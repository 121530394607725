import React, { useContext } from "react";
import moment from "moment";
// import { useQuery } from "@apollo/react-hooks";
// import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import { UserContext } from "../App";
// import { UPCOMING_TRACK_RESOURCES } from "./UpcomingTrack";
// import { EVENT_OCCURRENCES } from "./Home";
import { downloadICS } from "./ResourceDetail";

const UpcomingCommunity = ({ resourceList }) => {
  const currentUser = useContext(UserContext).user;

  const exclusiveIsValid = (resource, user) => {
    if (resource.kl2exclusive && !user.kl2Scholar) {
      return false;
    } else if (resource.traexclusive && !user.translationalResearchAcademy) {
      return false;
    } else if (resource.maexclusive && !user.mentorAcademy) {
      return false;
    } else {
      return true;
    }
  };

  const findMatches = (resourceCommunities, userCommunities) => {
    let result = [];
    resourceCommunities.forEach((val) => {
      if (userCommunities.find((element) => element.id === val.id)) {
        result.push(val.id);
      }
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
    // TODO: resourceCommunities.forEach(val => {
    //   console.log(userCommunities.includes(val))
    //   if (userCommunities.includes(val)){
    //     tempArray.push(val)
    //   }
    // })
    // if (tempArray.length > 0){
    //   return true;
    // }
    // else{
    //   return false
    // }
  };

  const matchesCommunity = (resource, user) => {
    if (
      user.subscriptionSet.find(
        (element) => element.resource.id === resource.id
      ) === undefined
    ) {
      if (findMatches(resource.communities, user.communities) === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };



  const filteredResourceList = resourceList.filter(
    //only return elements with a startDate greater than the current date and are not starred by the user
    (a) =>
      exclusiveIsValid(a.node, currentUser) &&
      matchesCommunity(a.node, currentUser) &&
      ((a.node.recurrences === "" &&
        new Date(a.node.startDate).getTime() >= Date.now()) ||
        (a.node.recurrences !== "" &&
        new Date(a.node.nextOccurrence).getTime() >= new Date().getTime())) &&
      currentUser.subscriptionSet.find(
        (element) => element.resource.id === a.node.id
      ) === undefined
  );

  // TODO: const { loading, error, data } = useQuery(UPCOMING_TRACK_RESOURCES, {
  //   variables: {
  //     search: currentUser.communities.map((community) => community.id),
  //   },
  // });

  // if (loading)
  //   return (
  //     <div className="spinner-border" role="status">
  //       <span className="sr-only">Loading...</span>
  //     </div>
  //   );
  // if (error) return `Error! ${error.message}`;

  return (
    <div className="card-body">
      {filteredResourceList
        .sort(
          (a, b) =>
            new Date(a.node.startDate).getTime() -
            new Date(b.node.startDate).getTime()
        )
        .slice(0, 3)
        .map((subscription) => (
          <div key={subscription.node.id}>
            {
              <div className="text-start">
                <div className="btn-group text-start">
                  <Subscribe resourceId={subscription.node.id} />
                  <div>
                    <Link
                      to={{
                        pathname: `resourcedetail/${subscription.node.id}`,
                        state: { resource: subscription.node },
                      }}
                    >
                      <div
                        className="btn-link ms-2 nav-link"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {subscription.node.title}{" "}
                      </div>
                    </Link>

                    <div className="d-flex align-items-start align-items-center">
                      {subscription.node.icsToggle === true && (
                        <button
                          type="button"
                          className="btn btn-sm d-inline-block"
                          style={{
                            backgroundColor: "transparent",
                          }}
                          onClick={function () {
                            downloadICS(subscription.node);
                          }}
                        >
                          <i
                            className="fa fa-calendar"
                            title="Add To Calendar"
                          />
                        </button>
                      )}
                      {subscription.node.icsToggle === false && (
                        <a
                          href={subscription.node.registrationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "black" }}
                        >
                          <i className="fa fa-pencil" title="Register" />
                        </a>
                      )}
                      <div className="d-inline-block ms-2">
                        {subscription.node.recurrences !== ""
                          ? moment(subscription.node.nextOccurrence).format("MMMM D, YYYY")
                          : moment(subscription.node.startDate).format(
                              "MMMM D, YYYY"
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            }
          </div>
        ))}
      {filteredResourceList.length > 3 && (
        <Link
          to={`../allresources`}
          className="card-footer-item link-underline link-underline-opacity-0 "
          style={{ float: "right", padding: "0" }}
        >
          View More
        </Link>
      )}
    </div>
  );
};

export default UpcomingCommunity;

