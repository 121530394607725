import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";
//import history from "./History";
//import { LOGIN_MUTATION } from "../Queries";
import { gql } from "apollo-boost";
import { useAlert } from "react-alert";

const Login = ({ setNewUser }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const alert = useAlert();
  
  const handleSubmit = async (event, tokenAuth, client) => {
    try {
      event.preventDefault();
      const res = await tokenAuth();
      console.log({ res });
      localStorage.setItem("authToken", res.data.tokenAuth.token);
      client.writeData({ data: { isLoggedIn: true } });
    }
    catch(err) {
      // alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
    }
  };

  //const [tokenAuth] = useMutation(TOKEN_AUTH);

  const LOGIN_MUTATION = gql`
    mutation($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
      }
    }
  `;

  return (
    <div className="container m-t-20">
      <h1 className="page0title">Login</h1>

      <div className="newnote-page m-t-20">
        <Mutation
          mutation={LOGIN_MUTATION}
          variables={{ username, password }}
          onCompleted={data => {
            console.log({ data });
          }}
        >
          {(tokenAuth, { loading, error, called, client }) => {
            return (
              <form onSubmit={event => handleSubmit(event, tokenAuth, client)}>
                <div className="field">
                  <label className="label">Username</label>
                  <div className="control">
                    <input
                      className="input"
                      name="title"
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Password</label>
                  <div className="control">
                    <input
                      className="input"
                      name="title"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <button className="button is-link" onClick={e => {}}>
                      Submit
                    </button>
                  </div>
                </div>

                <Link to={"../signup"} className="card-footer-item">
                  Need To Create An Account?
                </Link>
              </form>
            );
          }}
        </Mutation>
      </div>
    </div>
  );

  /*return (
    <div className="container m-t-20">
      <h1 className="page0title">Login</h1>

      <div className="newnote-page m-t-20">
        <form
          onSubmit={e => {
            e.preventDefault();
            tokenAuth({
              variables: {
                username,
                password
              }
            });
            history.push("/");
          }}
        >
          <div className="field">
            <label className="label">Username</label>
            <div className="control">
              <input
                className="input"
                name="title"
                type="text"
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Password</label>
            <div className="control">
              <input
                className="input"
                name="title"
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-link" onClick={e => {}}>
                Submit
              </button>
            </div>
          </div>
          <Link to={"../signup"} className="card-footer-item">
            Need To Create An Account?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;*/
};
export default Login;
