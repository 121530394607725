import React, { useContext } from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { UserContext, USER_QUERY } from "../App";
import { useAlert } from "react-alert";
import { CalendarToday } from "@material-ui/icons";
const Subscribe = ({ resourceId }) => {
  const currentUser = useContext(UserContext).user;
  const alert = useAlert();

  const handleDisabledSubscribe = () => {
    const userSubscriptions = currentUser.subscriptionSet;
    const isSubscribed =
      userSubscriptions.findIndex(
        ({ resource }) => resource.id === resourceId
      ) > -1;
    return isSubscribed;
  };
  
  return (
    <Mutation
      mutation={SUBSCRIBE_MUTATION}
      variables={{ resourceId }}
      onCompleted={(data) => {
        console.log({ data });
      }}
      refetchQueries={() => [{ query: USER_QUERY, variables: { groupNames: ["Resource Creator"] } }]}
    >
      {(createSubscription, { loading, error }) => {
        if (error) return `Error ${error.message}`;
        return (
          <div>
            <button
              className="btn btn-lg"
              style={{ backgroundColor: "transparent" }}
              disabled={handleDisabledSubscribe()}
              onClick={async (event) => {
                try {
                  await createSubscription();
                }
                catch (err) {
                   alert.show(err.message, {timeout: 0, position: 'top center', type: 'error'});
                }
              }}
            >
              <i class="fa-regular fa-star" style={{ color: "#cacc3f" }} />
            </button>
          </div>
        );
      }}
    </Mutation>
  );
};

const SUBSCRIBE_MUTATION = gql`
  mutation createSubscription($resourceId: String!) {
    createSubscription(input: { resourceId: $resourceId }) {
      user {
        username
      }
    }
  }
`;

export default Subscribe;
